import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./component/Login";
import Register from "./component/Register";
import DaftarDPT from "./component/DaftarDPT";
import VotersByUser from "./component/VotersByUser";
import AllVoters from "./component/AllVoters";
import SidebarComponent from "./component/SidebarComponent";
import ListRekruter from "./component/ListRekruter";
import DashboardFirst from "./component/DashboardFirst";
import MonitoringSection from "./component/MonitoringSection";
import VotersByRekruter from "./component/VotersByRekruter";
import './styles/style.css';
import CreateUserPage from "./component/CreateUserPage";
import Geomap from "./component/Geomap";
import EditUserPage from "./component/EditUserPage";

const DaftarDPTLayout = () => {
    return (
        <div>
            <SidebarComponent>
            <DaftarDPT />
            </SidebarComponent>
        </div>
    );
};

const UserVotersLayout = () => {
    return (
        <div>
            <SidebarComponent>
            <VotersByUser />
            </SidebarComponent>
        </div>
    );
};

const UserIdVotersLayout = () => {
    return (
        <div>
            <SidebarComponent>
            <VotersByRekruter />
            </SidebarComponent>
        </div>
    );
};

const CreateUserLayout = () => {
    return (
        <div>
            <SidebarComponent>
            <CreateUserPage />
            </SidebarComponent>
        </div>
    );
};

const EditUserLayout = () => {
    return (
        <div>
            <SidebarComponent>
            <EditUserPage />
            </SidebarComponent>
        </div>
    );
};

const AllVotersLayout = () => {
    return (
        <div>
            <SidebarComponent>
            <AllVoters />
            </SidebarComponent>
        </div>
    );
};

const AllRekrutersLayout = () => {
    return (
        <div>
            <SidebarComponent>
            <ListRekruter />
            </SidebarComponent>
        </div>
    );
};

const DashboardLayout = () => {
    return (
        <div className="bg-light">
            <SidebarComponent>
            <DashboardFirst />
            <MonitoringSection />
            </SidebarComponent>
        </div>
    );
};

const GeomapLayout = () => {
    return (
        <div className="bg-light">
            <SidebarComponent>
            <Geomap />
            </SidebarComponent>
        </div>
    );
};

function App() {
  return (
    <BrowserRouter>
        <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="/dashboard" element={<DashboardLayout />} />
        <Route path="/register" element={<Register />} />
        <Route path="/daftar-dpt" element={<DaftarDPTLayout/>} />
        <Route path="/user-voters" element={<UserVotersLayout/>} />
        <Route path="/all-voters" element={<AllVotersLayout/>} />
        <Route path="/rekruter/:userId" element={<UserIdVotersLayout />} />
        <Route path="/rekruters" element={<AllRekrutersLayout />} />
        <Route path="/create-user" element={<CreateUserLayout />} />
        <Route path="/edit-user/:userId" element={<EditUserLayout />} />
        <Route path="/geomap" element={<GeomapLayout />} />
    </Routes>
      </BrowserRouter>
  );
}

export default App;
