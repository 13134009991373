import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'chart.js/auto';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { MapContainer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Row, Table, ProgressBar } from 'react-bootstrap';
import { FaSpinner, FaChevronUp, FaChevronDown } from "react-icons/fa";

const Geomap = () => {
  const [districtData, setDistrictData] = useState([]);
  const [district, setDistrict] = useState('');
  const [village, setVillage] = useState('');
  const [districtVoters, setDistrictVoters] = useState([])
  const [villageVoters, setVillageVoters] = useState([])
  const [name, setName] = useState('');
  const [token, setToken] = useState('');
  const [expired, setExpired] = useState('');
  const navigate = useNavigate();
  const [districtGeojson, setDistrictGeojson] = useState(null);
  const [loadingDistrict, setLoadingDistrict] = useState(false);
  const [loadingVillage, setLoadingVillage] = useState(false);
  const [leftSlider, setLeftSlider] = useState(50);
  const [rightSlider, setRightSlider] = useState(70);
  const [asumsiKecamatan, setAsumsiKecamatan] = useState(0);
  const [asumsiDesa, setAsumsiDesa] = useState(0);

  useEffect(() => {
    if (sessionStorage.getItem('jabatan') != 'Admin') {
      navigate('/daftar-dpt');
    }
      refreshToken();
      getVoteByKecamatan();
  }, []);

  useEffect(() => {
    fetchDistrictGeoJSON();
  }, [districtData]);

  const refreshToken = async () => {
    try {
      const response = await axios.get('https://api.masboy.id/token');
      setToken(response.data.accessToken);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpired(decoded.exp);
    } catch (error) {
      if (error.response) {
        navigate('/');
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expired * 1000 < currentDate.getTime()) {
        const response = await axios.get('https://api.masboy.id/token');
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpired(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const fetchDistrictGeoJSON = async () => {
    try {
      const response = await fetch('/assets/geojsonData/kecamatan.geojson');  // Path di folder public
      const data = await response.json();
      setDistrictGeojson(data);
    } catch (error) {
      console.error("Error loading GeoJSON:", error);
    }
  };

  const fetchDistrictVoters = async (district, asumsiKecamatan) => {
    try {
      const response = await axiosJWT.post(
        'https://api.masboy.id/total-voters-by-desa',
        {
          kecamatan: district,
          asumsi_kecamatan: asumsiKecamatan,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setDistrictVoters(response.data);
      setLoadingDistrict(false);
      console.log('voters by desa:');
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchVillageVoters = async (district, village, asumsiKecamatan, asumsiDesa) => {
    try {
      const response = await axiosJWT.post(
        'https://api.masboy.id/total-voters-by-tps',
        {
          kecamatan: district,
          desa: village,
          asumsi_kecamatan: asumsiKecamatan,
          asumsi_desa: asumsiDesa,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setVillageVoters(response.data);
      console.log('voters by tps:')
      console.log(response.data)
      setLoadingVillage(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getVoteByKecamatan = async () => {
    try {
      const response = await axiosJWT.get('https://api.masboy.id/total-voters-by-kecamatan', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDistrictData(response.data);
      console.log(response.data); // Tambahkan ini
    } catch (error) {
      console.log(error);
    }
  };

  const getColor = (persentase) => {
    if (persentase > rightSlider) return '#198754';
    if (persentase > leftSlider) return '#ffc107';
    return '#dc3545';
  };

  const getVariant = (persentase) => {
    if (persentase >= rightSlider) {
      return 'success';  // Jika persentase > rightSlider
    } else if (persentase < leftSlider) {
      return 'danger';  // Jika persentase < leftSlider
    } else {
      return 'warning';  // Jika persentase di antara leftSlider dan rightSlider
    }
  };

  const getDistrictPercentage = (district) => {
    const kecamatan = districtData.find(kec => kec.kecamatan.toLowerCase().trim() === district.toLowerCase().trim());

    if (!districtData || !kecamatan) {
    console.log("district data null");}
    return kecamatan ? kecamatan.persentase : 0;
  };

  const geojsonStyle = (feature) => {
    const district = feature.properties.district;  // 'district' sesuai dengan properti di geoJSON
    const persentase = getDistrictPercentage(district);  // Dapatkan persentase dari data kecamatan
    console.log("persentase geojson", persentase);
    const color = getColor(persentase);  // Tentukan warna berdasarkan persentase
    return {
      color: 'black',
      fillColor: color,
      weight: 0.6,
      fillOpacity: 0.9
    };
  };

  const onEachFeature = (feature, layer) => {
    const district = feature.properties.district;
    console.log("on each feature district", district);
    const persentase = getDistrictPercentage(district);
    console.log("on each feature Percentage", persentase);

    const tooltipContent = `
      <strong>${district}</strong><br />
      Persentase: ${persentase.toFixed(2)}%
    `;

    layer.on({
      mouseover: (e) => {
        const layer = e.target;
        layer.bindTooltip(tooltipContent, {
          permanent: false,
          direction: 'center',
          className: 'district-tooltip'
        }).openTooltip();
        layer.setStyle({
          weight: 0.6,
          color: 'black',
          fillOpacity: 1
        });
      },
      mouseout: (e) => {
        const layer = e.target;
        layer.closeTooltip();
        layer.setStyle({
          weight: 0.6,
          color: 'black',
          fillOpacity: 0.9
        });
      },
      click: () => {
        // Set state untuk district
        setDistrict(feature.properties.district.toUpperCase());
        setVillageVoters([]);
        setDistrictVoters([]);
        setLoadingDistrict(true)

        // Panggil fungsi fetch data setelah setState
        fetchDistrictVoters(feature.properties.district.toUpperCase());
      }
    });
  };

  const MultiSlider = () => {  
    const handleIncrement1 = () => {
      if (leftSlider < rightSlider - 1) {  // Slider 1 tidak bisa sama atau lebih besar dari slider 2
        setLeftSlider(leftSlider + 1);
      }
    };

    const handleDecrement1 = () => {
      if (leftSlider > 0) {
        setLeftSlider(leftSlider - 1);
      }
    };

    // Fungsi untuk mengubah nilai slider 2
    const handleIncrement2 = () => {
      if (rightSlider < 100) {
        setRightSlider(rightSlider + 1);
      }
    };

    const handleDecrement2 = () => {
      if (rightSlider > leftSlider + 1) {  // Slider 2 tidak bisa lebih kecil dari slider 1
        setRightSlider(rightSlider - 1);
      }
    };

    return (
      <div className='d-flex gap-2 mt-3'>
        {/* Slider 1 */}
        <div className='p-3 d-flex flex-column gap-2 bg-white rounded shadow'>
          <label className='text-warning' style={{ fontWeight: 'bold', textAlign: 'center' }}>Batas Kuning (%)</label>
          <div className='d-flex gap-1 align-items-center justify-content-center'>
            <button onClick={handleDecrement1} className='rounded-circle border-0 bg-white d-flex align-items-center justify-content-center p-0' style={{ width: '28px', height: '28px' }} >
              <FaChevronDown />
            </button>
            <p className='my-auto text-center' style={{ width: '40px'}}>{leftSlider}</p>
            <button onClick={handleIncrement1} className='rounded-circle border-0 bg-white d-flex align-items-center justify-content-center p-0' style={{ width: '28px', height: '28px' }}>
              <FaChevronUp />
            </button>
          </div>
        </div>
        <br />
        {/* Slider 2 */}
        <div className='p-3 d-flex flex-column gap-2 bg-white rounded shadow'>
          <label style={{ fontWeight: 'bold', color: '#008000', textAlign: 'center' }}>Batas Hijau (%)</label>
          <div className='d-flex gap-1 align-items-center justify-content-center'>
            <button onClick={handleDecrement2} className='rounded-circle border-0 bg-white d-flex align-items-center justify-content-center p-0' style={{ width: '28px', height: '28px' }} >
              <FaChevronDown />
            </button>
            <p className='my-auto text-center' style={{ width: '40px'}}>{rightSlider}</p>
            <button onClick={handleIncrement2} className='rounded-circle border-0 bg-white d-flex align-items-center justify-content-center p-0' style={{ width: '28px', height: '28px' }}>
              <FaChevronUp />
            </button>
          </div>
        </div>
      </div>
    );
  };

  const position = [-7.0303889,109.390111]
  return (
    <div className='d-flex flex-column bg-white rounded py-5 px-5 mx-3 my-5 gap-5 shadow'>
      <div className='flex flex-col mb-5'>
        <h4 className="text-center h1 text-uppercase font-weight-bold" style={{ color: '#008000', fontWeight: 'bolder' }}>
          PETA SEBARAN KEKUATAN SUARA
        </h4>
        <p className='text-center mb-0 text-sm fst-italic fs-6'>Rumus target pemenangan dihitung dengan cara mengasumsikan jumlah kehadiran dari DPT sebanyak 70%.</p>
        <p className='text-center mb-0 text-sm fst-italic fs-6'>Hasil tersebut kemudian diasumsikan menjadi pemilih hari H,</p> 
        <p className='text-center mb-0 text-sm fst-italic fs-6'>dengan ambang batas {'>'} {leftSlider}% dari Asumsi Kehadiran =  Zona Kuning, dan  {'>'} {rightSlider}% dari Asumsi Kehadiran = Zona Hijau.</p>
        <p className='text-center mb-0 text-sm fst-italic fs-6'>*) AK = Asumsi Kehadiran</p>
      </div>
      <div className='d-flex gap-5'>
        <div className='d-flex flex-column gap-1 overflow-visible' >
          <Row className='rounded shadow' style={{ height: '450px', width: '380px'}} >
            <MapContainer center={position} zoom={10} zoomControl={false} zoomSnap={false} dragging={false} scrollWheelZoom={false}>
              {districtData.length > 0 && <GeoJSON data={districtGeojson} style={geojsonStyle} onEachFeature={onEachFeature} />}
            </MapContainer>
          </Row>
        </div>
        <div className='d-flex w-100'>
          <div className='d-flex flex-column justify-content-between w-100'>
            {districtData && (
              <div className="row">
                {/* Kolom kiri */}
                <div className="col-6">
                  {districtData.slice(0, 7).map((data, index) => (
                    <div style={{ cursor: 'pointer' }} className="d-flex flex-column w-100 gap-0 mb-1" onClick={() => {
                      setDistrict(data.kecamatan)
                      setVillageVoters([]);
                      setDistrictVoters([]);
                      setLoadingDistrict(true)
                      fetchDistrictVoters(data.kecamatan.toUpperCase(), data.asumsi_kecamatan);
                      }} key={index}>
                      <p className="mb-0 cursor-pointer" style={{ fontSize: '0.7rem', fontWeight: 'bold' }}>
                        {data.kecamatan + ' '}
                        <span className={`text-${getVariant(data.persentase)}`}>
                          ({data.persentase.toFixed(3)}% dari AK {data.asumsi_kecamatan.toFixed(0)})
                        </span>
                      </p>
                      <div className="d-flex align-items-center gap-3 justify-content-between">
                        <div className="d-flex flex-column w-100">
                          <ProgressBar
                            now={data.persentase}
                            variant={getVariant(data.persentase)}
                            style={{ borderRadius: '3px' }}
                            animated
                          />
                        </div>
                        <p className="mb-0" style={{ width: '100px' }}>{data.jumlah}/{data.dpt}</p>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Kolom kanan */}
                <div className="col-6">
                  {districtData.slice(7).map((data, index) => (
                    <div style={{ cursor: 'pointer' }} className="d-flex flex-column w-100 gap-0 mb-1" onClick={() => {
                      setDistrict(data.kecamatan);
                      setVillageVoters([]);
                      setDistrictVoters([]);
                      setLoadingDistrict(true);
                      fetchDistrictVoters(data.kecamatan.toUpperCase(), data.asumsi_kecamatan);
                      }} key={index}>
                      <p className="mb-0" style={{ fontSize: '0.7rem', fontWeight: 'bold' }}>
                        {data.kecamatan + ' '}
                        <span className={`text-${getVariant(data.persentase)}`}>
                          ({data.persentase.toFixed(3)}% dari AK {data.asumsi_kecamatan.toFixed(0)})
                        </span>
                      </p>
                      <div className="d-flex align-items-center gap-3 justify-content-between">
                        <div className="d-flex flex-column w-100">
                          <ProgressBar
                            now={data.persentase}
                            variant={getVariant(data.persentase)}
                            style={{ borderRadius: '3px' }}
                            animated
                          />
                        </div>
                        <p className="mb-0" style={{ width: '100px' }}>{data.jumlah}/{data.dpt}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <p className='fst-italic' style={{ fontSize: '0.8rem' }}>note: Pemilih/DPT</p>

            <MultiSlider />
          </div>
        </div>
      </div>
      <div className='d-flex flex-column align-items-center just-content-start w-100'>
        <h4 className="text-center text-uppercase mb-5" style={{  fontWeight: 'bolder', color: '#008000' }}>
          Data Kecamatan {district}
        </h4>
        <div className='d-flex gap-5 w-100'>
          <div className='d-flex flex-column w-100'>
            <h6 className="text-center text-uppercase" style={{  fontWeight: 'bolder', color: '#008000' }}>
              Data kelurahan
            </h6>
            <Table striped bordered hover variant="light">
              <thead className='text-center'>
                <tr>
                  <th>Kelurahan/Desa</th>
                  <th>Pemilih</th>
                  <th>DPT</th>
                  <th>Target Pemenangan</th>
                  <th>AK</th>
                </tr>
              </thead>
              <tbody>
                {loadingDistrict && (
                  <div className='d-flex justify-content-center'>
                    <FaSpinner className="loading-spinner mt-2 mx-auto" style={{ fontSize: '3rem', fill: '#008000', background: 'none' }} />
                  </div>
                )}
                {districtVoters.length > 0 &&
                  districtVoters.map((voter, index) => (
                    <tr key={index} onClick={() => {
                      setVillage(voter.desa);
                      setVillageVoters([]);
                      setLoadingVillage(true)
                      fetchVillageVoters(district, voter.desa, voter.asumsi_kecamatan, voter.asumsi_desa);
                      }}
                    >
                      <td style={{ fontWeight: 'bold' }}>{voter.desa}</td>
                      <td>{voter.jumlah}</td>
                      <td>{voter.dpt}</td>
                      <td className={`
                        ${voter.persentase > rightSlider ? 'text-success' :
                          voter.persentase > leftSlider ? 'text-warning' :
                          'text-danger'
                      }`}
                      style={{ fontWeight: 'bold' }}
                      >{voter.persentase.toFixed(2)}%</td>
                      <td>{voter.asumsi_desa.toFixed(0)}</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </div>
          {/* tabel TPS */}
          <div className='d-flex flex-column w-100'>
            <h6 className="text-center text-uppercase" style={{  fontWeight: 'bolder', color: '#008000' }}>
              Data TPS {village}
            </h6>
            <Table striped bordered hover variant="light">
              <thead className='text-center'>
                <tr>
                  <th>TPS</th>
                  <th>Pemilih</th>
                  <th>DPT</th>
                  <th>Target Pemenangan</th>
                  <th>AK</th>
                </tr>
              </thead>
              <tbody>
                {loadingVillage && (
                    <div className='d-flex justify-content-center'>
                    <FaSpinner className="loading-spinner mt-2 mx-auto" style={{ fontSize: '3rem', fill: '#008000', background: 'none' }} />
                  </div>
                )}
                {villageVoters.length > 0 &&
                  villageVoters.map((voter, index) => (
                    <tr key={index}>
                      <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                        {voter.tps}
                      </td>
                      <td>{voter.jumlah}</td>
                      <td>{voter.dpt}</td>
                      <td className={`
                        ${voter.persentase > rightSlider ? 'text-success' :
                          voter.persentase > leftSlider ? 'text-warning' :
                          'text-danger'
                      }`}
                      style={{ fontWeight: 'bold' }}
                      >{voter.persentase.toFixed(2)}%</td>
                      <td>{voter.asumsi_tps.toFixed(0)}</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Geomap;