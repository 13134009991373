import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Container, Row, Col, Alert, Form, Pagination } from 'react-bootstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import KopSurat from './assets/kop-surat.png';
import { FaFileExcel, FaFilePdf } from 'react-icons/fa';

const DaftarDPT = () => {
  const [name, setName] = useState('');
  const [token, setToken] = useState('');
  const [expired, setExpired] = useState('');
  const [voters, setVoters] = useState({});
  const [kecamatanOptions, setKecamatanOptions] = useState([]);
  const [desaOptions, setDesaOptions] = useState([]);
  const [selectedKecamatan, setSelectedKecamatan] = useState('');
  const [selectedDesa, setSelectedDesa] = useState('');
  const [selectedTps, setSelectedTps] = useState('');
  const [tpsOptions, setTpsOptions] = useState([]);
  const [selectedVoterIds, setSelectedVoterIds] = useState([]);
  const [msg, setMsg] = useState('');
  const [alertMode, setAlertMode] = useState('');
  
  // State for search
  const [searchName, setSearchName] = useState('');

  // Pagination states  
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem('jabatan') === 'Operator') {
      setSelectedKecamatan({ value: sessionStorage.getItem('kecamatan'), label: sessionStorage.getItem('kecamatan') });
    } else if (sessionStorage.getItem('jabatan') === 'Rekruter') {
      setSelectedKecamatan({ value: sessionStorage.getItem('kecamatan'), label: sessionStorage.getItem('kecamatan') });
      setSelectedDesa({value: sessionStorage.getItem('desa'), label: sessionStorage.getItem('desa') });
      setSelectedTps({value: sessionStorage.getItem('tps'), label: sessionStorage.getItem('tps') });
      fetchTpsOptions(sessionStorage.getItem('desa'));
    }
    refreshToken();
    fetchKecamatanOptions();
    getVoters();
  }, []);

  useEffect(() => {
    if (selectedDesa) {
      fetchTpsOptions(selectedDesa.value);
    } else {
      setTpsOptions([]);
      setSelectedTps('');
    }
  }, [selectedDesa]);

  useEffect(() => {
    if (selectedKecamatan) {
      fetchDesaOptions(selectedKecamatan.value);
    } else {
      setDesaOptions([]);
      setSelectedDesa('');
    }
    getVoters();
  }, [selectedKecamatan, selectedDesa, selectedTps]);

  useEffect(() => {
    getVoters();
  }, [rowsPerPage, currentPage]);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`https://api.masboy.id/token`);
      setToken(response.data.accessToken);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpired(decoded.exp);
    } catch (error) {
      if (error.response) {
        navigate('/');
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expired * 1000 < currentDate.getTime()) {
        const response = await axios.get('https://api.masboy.id/token');
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpired(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getVoters = async () => {
    try {
      const response = await axiosJWT.get(`https://api.masboy.id/voters`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          // kecamatan: selectedKecamatan ? selectedKecamatan.value : '',
          // desa: selectedDesa? selectedDesa.value : '',
          // tps: selectedTps? selectedTps.value : '',
          kecamatan: selectedKecamatan ? selectedKecamatan.value : (sessionStorage.getItem('kecamatan')? sessionStorage.getItem('kecamatan') : ''),
          desa: selectedDesa? selectedDesa.value : (sessionStorage.getItem('desa')? sessionStorage.getItem('desa') : ''),
          tps: selectedTps? selectedTps.value : '',
          page: currentPage, // Menyertakan parameter untuk halaman saat meminta data
          limit: rowsPerPage, // Menyertakan parameter untuk batasan data saat meminta data
        },
      });
      setVoters(response.data);
      console.log(`Kecamatan: ${selectedKecamatan}`);
      console.log(`Desa: ${selectedDesa}`);
      console.log(`TPS: ${selectedTps}`);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchKecamatanOptions = async () => {
    try {
      const response = await axiosJWT.get('https://api.masboy.id/kecamatan', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setKecamatanOptions(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDesaOptions = async (kecamatan) => {
    try {
      const response = await axiosJWT.get(`https://api.masboy.id/desa`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          kecamatan: kecamatan,
        },
      });
      setDesaOptions(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTpsOptions = async (desa) => {
    try {
      const response = await axiosJWT.get(`https://api.masboy.id/tps`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          desa: desa,
        },
      });
      setTpsOptions(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleKecamatanChange = (selectedOption) => {
    setSelectedKecamatan(selectedOption);
    setSelectedDesa(''); // Reset desa when kecamatan changes
    setCurrentPage(1); // Reset to the first page when kecamatan changes
  };

  const handleDesaChange = (selectedOption) => {
    setSelectedDesa(selectedOption);
    setCurrentPage(1); // Reset to the first page when kecamatan changes
  };

  const handleTpsChange = (selectedOption) => {
    setSelectedTps(selectedOption);
    setCurrentPage(1); // Reset to the first page when kecamatan changes
  };

  const handleVoterSelect = (id) => {
    setSelectedVoterIds((prev) =>
      prev.includes(id) ? prev.filter((voterId) => voterId !== id) : [...prev, id]
    );
  };

  const handleSelectAll = () => {
    if (selectedVoterIds.length === currentVoters.length) {
      setSelectedVoterIds([]); // Deselect all if all are selected
    } else {
      const allVoterIds = currentVoters.map(voter => voter.id);
      setSelectedVoterIds(allVoterIds); // Select all
    }
  };

  const handleSubmit = async () => {
    if (selectedVoterIds.length === 0) {
      setMsg('Silakan pilih pemilih terlebih dahulu!');
      setAlertMode('danger');
      return;
    }

    try {
      const response = await axiosJWT.post('https://api.masboy.id/assign-voter', {
        voterIds: selectedVoterIds,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMsg(response.data.msg);
      setAlertMode('success');
      setSelectedVoterIds([]); // Reset selected voter IDs after submission
      getVoters(); // Refresh the voter list
    } catch (error) {
      console.log(error);
      setMsg('Terjadi kesalahan saat menambahkan pemilih!');
      setAlertMode('danger');
    }
  };

  const currentVoters = voters.data?.filter(voter => voter.nama.toLowerCase().includes(searchName.toLowerCase()));

  // Calculate total pages
  const totalVoters = voters.totalVoters;
  const totalPages = Math.ceil(totalVoters / rowsPerPage);
  const maxPageNumbersToShow = 5;

  // Calculate the range of page numbers to show
  const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
  const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page when changing rows per page
  };

  // Helper function to generate filter summary
  const getFilterSummary = () => {
    return `Kecamatan: ${selectedKecamatan? selectedKecamatan.value : 'Semua'}, Desa: ${selectedDesa? selectedDesa.value : 'Semua'}, TPS: ${selectedTps? selectedTps.value : 'Semua'}`;
  };

  const getFormattedDateTime = () => {
    const now = new Date();
    const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
    const months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
  
    const dayName = days[now.getDay()];
    const day = now.getDate();
    const monthName = months[now.getMonth()];
    const year = now.getFullYear();
  
    // Format waktu dengan menambahkan leading zero jika diperlukan
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
  
    return `${dayName}, ${day} ${monthName} ${year} ${hours}:${minutes}`;
  };

  // Export to PDF
 // Export to PDF
 const exportToPDF = () => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  let startY = 10;
  
  // Fungsi untuk menambah header pada halaman pertama
  const addHeader = (imgData) => {
  
    // Set lebar gambar sesuai dengan lebar dokumen dan jaga rasio aspek gambar
    const imgWidth = pageWidth; // Gambar memenuhi lebar dokumen
    const imgHeight = (13 / 100) * pageHeight; // Sesuaikan tinggi gambar dengan menjaga rasio aspek
  
    // Menambahkan gambar di bagian atas header
    doc.addImage(imgData, 'PNG', 0, 10, imgWidth, imgHeight); // Menempatkan gambar di bagian atas, mulai dari x=0
  
    // Set font size and family
    doc.setFont("times", "bold");
    doc.setTextColor(0, 0, 0); // Warna teks hitam
  
    // Mengatur jarak startY di bawah gambar
    const startY = imgHeight + 20; // Tambahkan jarak lebih besar jika teks tidak muncul (dari 20 ke 30)
  
    doc.setFontSize(12);
    doc.setFont("times", "bold"); // Mengatur font ke Times New Roman
    doc.text('DAFTAR PEMILIH TETAP', pageWidth / 2, startY, { align: 'center' });
    doc.text('PEMILIHAN BUPATI DAN WAKIL BUPATI PEMALANG 2024', pageWidth / 2, startY + 7, { align: 'center' });

    doc.setFontSize(12);
    doc.setFont("times", "normal");
    const rightX = pageWidth - 15; // Posisi x untuk teks yang diselaraskan ke kanan
    doc.text(`KECAMATAN: ${selectedKecamatan? selectedKecamatan.value : 'SEMUA'}`, 15, startY + 14);
    doc.text(`DESA: ${selectedDesa? selectedDesa.value : 'SEMUA'}`, 15, startY + 21);
    doc.text(`TPS: ${selectedTps? selectedTps.value : 'SEMUA'}`, 15, startY + 28);
    doc.text(`${getFormattedDateTime().toUpperCase()}`, rightX, startY + 14, { align: 'right' }); // Ditaruh di kanan
    doc.text(`JUMLAH DATA: ${currentVoters.length}`, rightX, startY + 21, { align: 'right' }); // Ditaruh di kanan
};   

  // Fungsi untuk menambah footer halaman
  const addFooter = (page) => {
    doc.setFontSize(12);
    doc.setFont("times", "normal"); // Mengatur font ke Times New Roman
    doc.text(`HALAMAN ${page}`, 105, pageHeight - 10, { align: 'center' });
  };

  // Menambahkan header di halaman pertama
  addHeader(KopSurat);
  
  // Tabel data dimulai di bawah header
  let tableY = startY + 81;

  doc.autoTable({
    startY: tableY,
    head: [['NO', 'NAMA', 'UMUR', 'L/P', 'KECAMATAN', 'DESA', 'TPS']],
    body: currentVoters.map((voter, index) => [
      index + 1,
      voter.nama.toUpperCase(),
      voter.umur,
      voter.kelamin.toUpperCase(),
      voter.kecamatan.toUpperCase(),
      voter.desa.toUpperCase(),
      voter.tps
    ]),
    margin: { top: 20 },
    didDrawPage: (data) => {
      let pageCount = doc.internal.getNumberOfPages();
      if (pageCount > 1) {
        doc.setPage(pageCount);
        addFooter(pageCount);
      }
    },
    addPageContent: (data) => {
      if (data.pageCount > 1) {
        doc.setFontSize(10);
        addFooter(data.pageCount);
      }
    },
    styles: {
      fontSize: 10,
      font: "times", // Mengatur font untuk isi tabel ke Times New Roman
      lineWidth: 0.5, // Ketebalan garis border
      textColor: [0, 0, 0], // Warna border hitam
      lineColor: [0, 0, 0], // Warna border hitam
    },
    headStyles: {
      fillColor: [30, 30, 30],
      halign: 'center',
      textColor: [255, 255, 255], // Warna teks di header
      lineWidth: 0.5, // Ketebalan garis border untuk header
      lineColor: [0, 0, 0], // Warna border hitam untuk header
    },
    columnStyles: {
      0: {cellWidth: 10 },
      1: { cellWidth: 35 },
      2: { cellWidth: 20 },
      3: {  cellWidth: 10 },
      4: {  cellWidth: 30 },
      5: {  cellWidth: 35 },
      6: {  cellWidth: 15 },
    },
  });

  // Menyimpan dokumen PDF
  doc.save(`DAFTAR_PEMILIH_TETAP_${selectedKecamatan? selectedKecamatan.value : 'SEMUA'}_${selectedDesa? selectedDesa.value : 'SEMUA'}_${selectedTps? selectedTps.value : 'SEMUA'}.pdf`);
};


  // Export to Excel
  const exportToExcel = () => {
    const filterSummary = getFilterSummary();
    const worksheetData = [
      ['DATA PEMILIH TETAP'],
      ['FILTER:', filterSummary.toUpperCase()],
      ['JUMLAH DATA:', currentVoters.length],
      [],
      ['NO', 'NAMA', 'UMUR', 'L/P', 'KECAMATAN', 'DESA', 'TPS'],
      ...currentVoters.map((voter, index) => [
        index + 1,
      voter.nama.toUpperCase(),
      voter.umur,
      voter.kelamin.toUpperCase(),
      voter.kecamatan.toUpperCase(),
      voter.desa.toUpperCase(),
      voter.tps
      ]),
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'DAFTAR PEMILIH');
    XLSX.writeFile(workbook, `DAFTAR_PEMILIH_TETAP_${selectedKecamatan? selectedKecamatan.value : 'SEMUA'}_${selectedDesa? selectedDesa.value : 'SEMUA'}_${selectedTps? selectedTps.value : 'SEMUA'}.xlsx`);
  };

  return (
    <Container className="mt-5 p-5">
      <h2 className="mb-5 text-center text-uppercase h1"
        style={{ 
          color: '#008000',
          fontWeight: 'bolder'
         }}
      >Daftar DPT Kabupaten Pemalang</h2>
      <hr />
      {msg && <Alert variant={alertMode} className="text-center" role='button' onClick={() => setMsg('')}>{msg}</Alert>}
      <div className="sticky-top bg-light border p-3" style={{top: 68}}>
        <Row className="align-items-center">
          <Col md={6}>
            <Form.Control
              type="text"
              placeholder="Cari berdasarkan nama..."
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
            />
          </Col>
          <Col md={2}>
          <Form.Select value={rowsPerPage} onChange={handleRowsPerPageChange}>
            <option value={100}>100</option>
            <option value={250}>250</option>
            <option value={500}>500</option>
            <option value={totalVoters}>Semua</option>
          </Form.Select>
          </Col>
          <Col md={4} className="text-end">
            <Button onClick={handleSubmit} style={{ backgroundColor: '#008000', border: 'none' }}>Tambahkan Pemilih Terpilih</Button>
            <span className="ms-3">{selectedVoterIds.length} pemilih terpilih</span>
          </Col>
        </Row>
      </div>
      <div className="bg-light border p-3">
      <Row>
        <Col>
          <Select
          value={selectedKecamatan ? selectedKecamatan : (sessionStorage.getItem('kecamatan')? {value: sessionStorage.getItem('kecamatan'), label: sessionStorage.getItem('kecamatan')} : '')}
          onChange={handleKecamatanChange}
          options={kecamatanOptions.map((kec) => ({ value: kec.kecamatan, label: kec.kecamatan }))}
          placeholder="Pilih Kecamatan"
          isClearable
          noOptionsMessage={() => 'Tidak ada data kecamatan'}
          isDisabled={sessionStorage.getItem('role') !== 'Admin'}
          />
        </Col>
        <Col>
        <Select
          value={selectedDesa ? selectedDesa : (sessionStorage.getItem('desa')? {value: sessionStorage.getItem('desa'), label: sessionStorage.getItem('desa')} : '')}
          onChange={handleDesaChange}
          options={desaOptions.map((desa) => ({ value: desa.desa, label: desa.desa }))}
          placeholder="Pilih Desa"
          isClearable
          noOptionsMessage={() => 'Tidak ada data desa'}
          isDisabled={!selectedKecamatan || sessionStorage.getItem('role') === 'Rekruter'}
          />
        </Col>
        <Col>
        <Select
          value={selectedTps}
          onChange={handleTpsChange}
          options={tpsOptions.map((tps) => ({ value: tps.tps, label: tps.tps }))}
          placeholder="Pilih TPS"
          isClearable
          noOptionsMessage={() => 'Tidak ada data TPS'}
          isDisabled={!selectedDesa && sessionStorage.getItem('role') !== 'Rekruter'}
          />
        </Col>
        <Col md={3} className="text-start d-flex gap-2">
          <Button variant="success" onClick={() => {
              if (currentVoters.length > 0) {
                exportToExcel();
              } else {
                setMsg('Tidak ada data untuk diekspor!');
              }
            }} className="me-2 d-flex gap-1 align-items-center"
            style={{ backgroundColor: '#008000' }}
          >
            <FaFileExcel />
            Unduh Excel
          </Button>
          <Button variant="danger" onClick={() => {
          if (currentVoters.length > 0) {
            exportToPDF();
          } else {
            setMsg('Tidak ada data untuk diekspor!');
          }
        }} className="me-2 d-flex gap-1 align-items-center"
        >
            <FaFilePdf />
            Unduh PDF
          </Button>
          </Col>
      </Row>
      </div>
      {voters.data?.length > 0 ? (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>No</th>
                <th>Nama</th>
                <th>Umur</th>
                <th>Kelamin</th>
                <th>Kecamatan</th>
                <th>Desa</th>
                <th>TPS</th>
                <th>
                <Form.Check
                  type="checkbox"
                  checked={selectedVoterIds.length === currentVoters.length}
                  onChange={handleSelectAll}
                />
                </th>
              </tr>
            </thead>
            <tbody>
              {currentVoters.map((voter, index) => (
                <tr key={voter.id} onClick={() => handleVoterSelect(voter.id)}>
                  <td>{index+1+(currentPage*rowsPerPage-rowsPerPage)}</td>
                  <td>{voter.nama}</td>
                  <td>{voter.umur}</td>
                  <td>{voter.kelamin}</td>
                  <td>{voter.kecamatan}</td>
                  <td>{voter.desa}</td>
                  <td>{voter.tps}</td>
                  <td onClick={(e) => e.stopPropagation()}>
                    <Form.Check
                      type="checkbox"
                      checked={selectedVoterIds.includes(voter.id)}
                      onChange={() => handleVoterSelect(voter.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination className="justify-content-center">
            {startPage > 1 && (
              <Pagination.First onClick={() => paginate(1)} />
            )}
            {Array.from({ length: endPage - startPage + 1 }, (_, idx) => startPage + idx).map((number) => (
              <Pagination.Item
                key={number}
                active={number === currentPage}
                onClick={() => paginate(number)}
                className={number === currentPage ? 'pagination-item-active' : ''}
              >
                {number}
              </Pagination.Item>
            ))}
            {endPage < totalPages && (
              <Pagination.Last onClick={() => paginate(totalPages)} />
            )}
          </Pagination>
        </>
      ) : (
        <h3 className="mb-2 text-danger text-center text-uppercase h6 mt-3"
        style={{ 
          color: '#008000'
        }}
        >Belum ada DPT!</h3>
      )}
    </Container>
  );
};

export default DaftarDPT;
