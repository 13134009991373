import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, ProgressBar, Form } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { FaMale, FaFemale } from "react-icons/fa";

const MonitoringSection = () => {
  const [name, setName] = useState('');
  const [token, setToken] = useState('');
  const [expired, setExpired] = useState('');
  const [voteByKecamatan, setVoteByKecamatan] = useState([]);
  const [label, setLabel] = useState([]);
  const [dpt, setDpt] = useState(0);
  const [kecamatanCount, setKecamatanCount] = useState(0);
  const [desaCount, setDesaCount] = useState(0);
  const [tpsCount, setTpsCount] = useState(0);
  const [totalVoter, setTotalVoter] = useState(0);
  const [kecamatanOptions, setKecamatanOptions] = useState([]);
  const [desaOptions, setDesaOptions] = useState([]);
  const [selectedKecamatan, setSelectedKecamatan] = useState('');
  const [selectedDesa, setSelectedDesa] = useState('');
  const [votersDemographic, setVotersDemographic] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem('jabatan') === 'Operator') {
      setSelectedKecamatan({ value: sessionStorage.getItem('kecamatan'), label: sessionStorage.getItem('kecamatan') });
    } else if (sessionStorage.getItem('jabatan') === 'Rekruter') {
      setSelectedKecamatan({ value: sessionStorage.getItem('kecamatan'), label: sessionStorage.getItem('kecamatan') });
      setSelectedDesa({ value: sessionStorage.getItem('desa'), label: sessionStorage.getItem('desa') });
    }
    refreshToken();
    fetchKecamatanOptions();
    getVoteByKecamatan();
    getTotalDpt();
    getTotalVoters();
    getKecamatanCount();
    getVotersDemographic();
    getDesaCount();
    getTpsCount();
  }, []);

  useEffect(() => {
    if (selectedKecamatan) {
      fetchDesaOptions(selectedKecamatan ? selectedKecamatan.value : sessionStorage.getItem('kecamatan'));
      if (sessionStorage.getItem('jabatan') === 'Rekruter') {
        fetchVillageVoters(selectedKecamatan ? selectedKecamatan.value : sessionStorage.getItem('kecamatan'), sessionStorage.getItem('desa'));
      } else {
        fetchDistrictVoters(selectedKecamatan ? selectedKecamatan.value : sessionStorage.getItem('kecamatan'));
      }
      getTotalDpt();
      getTotalVoters();
      getKecamatanCount();
      getDesaCount();
      getTpsCount();
      getVotersDemographic();
    } else {
      setDesaOptions([]);
      setSelectedDesa('');
      getVoteByKecamatan();
      getTotalDpt();
      getTotalVoters();
      getKecamatanCount();
      getDesaCount();
      getTpsCount();
      getVotersDemographic();
    }
  }, [selectedKecamatan, selectedDesa]);

  useEffect(() => {
    if (selectedDesa) {
      console.log(selectedDesa);
      fetchVillageVoters(selectedKecamatan ? selectedKecamatan.value : sessionStorage.getItem('kecamatan'), selectedDesa ? selectedDesa.value : sessionStorage.getItem('desa')); 
      getTotalDpt();
      getTotalVoters();
      getDesaCount();
      getTpsCount();
      getVotersDemographic();
    }
  }, [selectedDesa]);

  const refreshToken = async () => {
    try {
      const response = await axios.get('https://api.masboy.id/token');
      setToken(response.data.accessToken);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpired(decoded.exp);
    } catch (error) {
      if (error.response) {
        navigate('/');
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expired * 1000 < currentDate.getTime()) {
        const response = await axios.get('https://api.masboy.id/token');
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpired(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  const getVoteByKecamatan = async () => {
    try {
      const response = await axiosJWT.get('https://api.masboy.id/total-voters-by-kecamatan', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setVoteByKecamatan(response.data);
      setLabel(response.data.map(item => item.kecamatan));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDistrictVoters = async (district) => {
    try {
      const response = await axiosJWT.post(
        'https://api.masboy.id/total-voters-by-desa',
        {
          kecamatan: district
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setVoteByKecamatan(response.data);
      setLabel(response.data.map(item => item.desa));
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchVillageVoters = async (district, village) => {
    try {
      const response = await axiosJWT.post(
        'https://api.masboy.id/total-voters-by-tps',
        {
          kecamatan: district,
          desa: village
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setVoteByKecamatan(response.data);
      setLabel(response.data.map(item => item.tps));
      console.log('masuk');
    } catch (error) {
      console.log(error);
    }
  };

  const getTotalDpt = async () => {
    try {
      const response = await axiosJWT.get('https://api.masboy.id/total-dpt', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          kecamatan: selectedKecamatan ? selectedKecamatan.value : (sessionStorage.getItem('kecamatan') ? sessionStorage.getItem('kecamatan') : ''),
          desa: selectedDesa? selectedDesa.value : (sessionStorage.getItem('desa') ? sessionStorage.getItem('desa') : '')
        },
      });
      setDpt(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getVotersDemographic = async () => {
    try {
      const response = await axiosJWT.get('https://api.masboy.id/voters-demographic', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          kecamatan: selectedKecamatan ? selectedKecamatan.value : (sessionStorage.getItem('kecamatan') ? sessionStorage.getItem('kecamatan') : ''),
          desa: selectedDesa ? selectedDesa.value : (sessionStorage.getItem('desa') ? sessionStorage.getItem('desa') : '')
        },
      });
      setVotersDemographic(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getTotalVoters = async () => {
    try {
      const response = await axiosJWT.get('https://api.masboy.id/total-voters', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          kecamatan: selectedKecamatan ? selectedKecamatan.value : (sessionStorage.getItem('kecamatan') ? sessionStorage.getItem('kecamatan') : ''),
          desa: selectedDesa? selectedDesa.value : (sessionStorage.getItem('desa') ? sessionStorage.getItem('desa') : '')
        },
      });
      setTotalVoter(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchKecamatanOptions = async () => {
    try {
      const response = await axiosJWT.get('https://api.masboy.id/kecamatan', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setKecamatanOptions(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDesaOptions = async (kecamatan) => {
    try {
      const response = await axiosJWT.get(`https://api.masboy.id/desa`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          kecamatan: kecamatan,
        },
      });
      setDesaOptions(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleKecamatanChange = (selectedOption) => {
    setSelectedKecamatan(selectedOption);
    setSelectedDesa(''); // Reset desa when kecamatan changes
  };

  const handleDesaChange = (selectedOption) => {
    setSelectedDesa(selectedOption);
  };

  const getKecamatanCount = async () => {
    try {
      const response = await axiosJWT.get('https://api.masboy.id/kecamatan-count', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          kecamatan: selectedKecamatan ? selectedKecamatan.value : (sessionStorage.getItem('kecamatan') ? sessionStorage.getItem('kecamatan') : ''),
        },
      });
      setKecamatanCount(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getDesaCount = async () => {
    try {
      const response = await axiosJWT.get('https://api.masboy.id/desa-count', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          kecamatan: selectedKecamatan ? selectedKecamatan.value : (sessionStorage.getItem('kecamatan') ? sessionStorage.getItem('kecamatan') : ''),
          desa: selectedDesa? selectedDesa.value : (sessionStorage.getItem('desa') ? sessionStorage.getItem('desa') : '')
        },
      });
      setDesaCount(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getTpsCount = async () => {
    try {
      const response = await axiosJWT.get('https://api.masboy.id/tps-count', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          kecamatan: selectedKecamatan ? selectedKecamatan.value : (sessionStorage.getItem('kecamatan') ? sessionStorage.getItem('kecamatan') : ''),
          desa: selectedDesa? selectedDesa.value : (sessionStorage.getItem('desa') ? sessionStorage.getItem('desa') : '')
        },
      });
      setTpsCount(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  // Statistik keseluruhan
  const statistics = {
    totalDPT: dpt,
    jumlahPemilih: totalVoter, // Contoh jumlah pemilih
    totalKecamatan: kecamatanCount,
    totalDesa: desaCount,
    jumlahTPS: tpsCount,
  };

  // Data untuk bar chart
  const barData = {
    labels: label,
    datasets: [
      {
        label: 'Jumlah Pemilih',
        backgroundColor: '#008000',
        data: voteByKecamatan.map(item => item.jumlah),
      },
    ],
  };

  // Opsi untuk bar chart
  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          autoSkip: false,
        },
      },
    },
  };

  // Komponen untuk menampilkan bar usia dan jenis kelamin
  const AgeGenderBar = ({ title, value, color, kelamin }) => (
    <div className="d-flex align-items-center mb-1">
      <span style={{ width: '70px' }}>{title}</span>
      <ProgressBar
        now={value}
        max={1}
        style={{ flexGrow: 1, margin: '0 10px' }}
        variant={color}
      />
      <span className={`text-${color}`} style={{ fontWeight: 'bold' }}>{(value*100).toFixed(2)} %</span>
    </div>
  );

  return (
    <>
    {sessionStorage.getItem('jabatan') !== 'Rekruter' && (
      <div className="px-5 py-3">
      <Row>
        <Col>
          <Select
          value={selectedKecamatan ? selectedKecamatan : sessionStorage.getItem('kecamatan') ? { value: sessionStorage.getItem('kecamatan'), label: sessionStorage.getItem('kecamatan') } : ''}
          onChange={handleKecamatanChange}
          options={kecamatanOptions.map((kec) => ({ value: kec.kecamatan, label: kec.kecamatan }))}
          placeholder="Pilih Kecamatan"
          isClearable
          noOptionsMessage={() => 'Tidak ada data kecamatan'}
          isDisabled={sessionStorage.getItem('jabatan') !== 'Admin'}
          />
        </Col>
        <Col>
        <Select
          value={selectedDesa ? selectedDesa : sessionStorage.getItem('desa') ? { value: sessionStorage.getItem('desa'), label: sessionStorage.getItem('desa') } : '' }
          onChange={handleDesaChange}
          options={desaOptions.map((desa) => ({ value: desa.desa, label: desa.desa }))}
          placeholder="Pilih Desa"
          isClearable
          noOptionsMessage={() => 'Tidak ada data desa'}
          isDisabled={!selectedKecamatan || sessionStorage.getItem('role') === 'Rekruter'}
          />
        </Col>
      </Row>
      </div>
    )}
  <div className='bg-light'>
    <Container className='my-2'>
        <Row className='mx-3 px-5 py-5 rounded shadow bg-white'>
          {/* Chart Section */}
          <h4 className="text-center h1 mb-5 text-uppercase" style={{ color: '#008000', fontWeight: 'bolder' }}>Monitoring Wilayah</h4>
          <Col md={7}>
            <div style={{ height: '320px', width: '560px', overflowX: 'auto' }}>
              <Bar data={barData} options={barOptions} />
            </div>
          </Col>

          {/* Statistik Section */}
          <Col md={5} className='my-auto p-0'>
            <div className="container ">
              <div className='row gap-3 mb-3'>
                <Card border='success' text="white" style={{ backgroundColor: '#008000' }} className="col text-center">
                  <Card.Body>
                    <Card.Title className='h6'>Jumlah Pemilih</Card.Title>
                    <Card.Text className='h3'>{statistics.jumlahPemilih.toLocaleString()}</Card.Text>
                  </Card.Body>
                </Card>
              </div>
              <div className='row gap-3 mb-3'>
                <Card bg="white" border='success' text="success" className="col text-center p-1">
                  <Card.Body>
                    <Card.Title className='h6'>Total DPT</Card.Title>
                    <Card.Text className='h4'>{statistics.totalDPT.toLocaleString()}</Card.Text>
                  </Card.Body>
                </Card>
              </div>
              <div className='row gap-3 mb-3'>
                <Card bg="white" border='success' text="success" className="col text-center">
                  <Card.Body>
                    <Card.Title style={{ fontSize: '0.7rem' }}>Kecamatan</Card.Title>
                    <Card.Text className='h4'>{statistics.totalKecamatan}</Card.Text>
                  </Card.Body>
                </Card>
                <Card bg="white" border='success' text="success" className="col text-center">
                  <Card.Body>
                    <Card.Title style={{ fontSize: '0.7rem' }}>Kel./Desa</Card.Title>
                    <Card.Text className='h4'>{statistics.totalDesa}</Card.Text>
                  </Card.Body>
                </Card>
                <Card bg="white" border='success' text="success" className="col text-center p-1">
                  <Card.Body>
                    <Card.Title style={{ fontSize: '0.7rem' }}>TPS</Card.Title>
                    <Card.Text className='h4 font-weight-bold'>{statistics.jumlahTPS.toLocaleString()}</Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="my-4">
      <Row>
        <Col>
          <div className="mx-3 py-5 px-3 rounded bg-white shadow">
            <Card.Body className='px-5'>
              <h4 className="text-center h1 text-uppercase font-weight-bold mb-5" style={{ color: '#008000', fontWeight: 'bolder' }}>Statistik Pemilih</h4>
              <p className='text-center bg-white'
                  style={{ 
                    fontWeight: 'bold'
                  }}
              >Total Pemilih</p>
              <h6 
                  className="text-center h1 mb-3 py-2 w-25 mx-auto rounded" 
                  style={{ 
                    border: '3px solid #008000',
                    color: '#008000',
                    fontWeight: 'bolder'
                  }}>
                {statistics.jumlahPemilih}
              </h6>
              <div className='d-flex gap-5 mt-5'>
                <div className='d-flex flex-column w-100 rounded  px-3 py-2 pt-4 bg-white'>
                  <Col className='mb-3'>
                    <p className='h3 text-center' style={{ color: '#02ccFE' }}>
                      <FaMale /> {votersDemographic.totalLaki} Laki-laki
                    </p>
                  </Col>
                  {votersDemographic.laki?.map((item, index) => (
                    <AgeGenderBar kelamin={'L'} key={index} title={item.generasi} value={parseInt(item.total)/votersDemographic.totalLaki} color="info" />
                  ))}
                </div>
                <div className='d-flex flex-column w-100 rounded  px-3 py-2 pt-4 bg-white'>
                  <Col className='mb-3'>
                    <p className='h3 text-center text-danger'>
                      <FaFemale /> {votersDemographic.totalPerempuan} Perempuan
                    </p>
                  </Col>
                  {votersDemographic.perempuan?.map((item, index) => (
                    <AgeGenderBar kelamin={'P'} key={index} title={item.generasi} value={parseInt(item.total)/votersDemographic.totalPerempuan} color="danger" />
                  ))}
                </div>
              </div>
            </Card.Body>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
    </>
  );
};

export default MonitoringSection;
