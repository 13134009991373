import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { jwtDecode } from 'jwt-decode';
import { useNavigate, useParams } from 'react-router-dom';
import { Table, Button, Container, Row, Col, Alert, Form, Pagination } from 'react-bootstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import KopSurat from './assets/kop-surat.png';
import { FaFileExcel, FaFilePdf, FaTrashAlt  } from 'react-icons/fa';
import { IoMdArrowRoundBack } from "react-icons/io";

const VotersByRekruter = () => {
  const { userId } = useParams(); // Mengambil ID dari URL
  const [name, setName] = useState('');
  const [token, setToken] = useState('');
  const [expired, setExpired] = useState('');
  const [voters, setVoters] = useState([]);
  const [kecamatanOptions, setKecamatanOptions] = useState([]);
  const [desaOptions, setDesaOptions] = useState([]);
  const [selectedKecamatan, setSelectedKecamatan] = useState('');
  const [selectedDesa, setSelectedDesa] = useState('');
  const [selectedTps, setSelectedTps] = useState('');
  const [tpsOptions, setTpsOptions] = useState([]); // State untuk opsi TPS
  const [selectedVoterIds, setSelectedVoterIds] = useState([]);
  const [msg, setMsg] = useState('');
  const [alertMode, setAlertMode] = useState('');
  const [selectedRekruterName, setSelectedRekruterName] = useState('');
  // State for search
  const [searchName, setSearchName] = useState('');
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1); // Halaman saat ini
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem('jabatan') == 'Rekruter') {
      navigate('/daftar-dpt');
    }
    refreshToken();
    fetchKecamatanOptions();
    getVoters();
    fetchRekruter(userId);
  }, []);

  useEffect(() => {
    if (selectedDesa) {
      fetchTpsOptions(selectedDesa.value);
    } else {
      setTpsOptions([]);
      setSelectedTps('');
    }
  }, [selectedDesa]);

  useEffect(() => {
    if (selectedKecamatan) {
      fetchDesaOptions(selectedKecamatan.value);
    } else {
      setDesaOptions([]);
      setSelectedDesa('');
    }
    getVoters();
  }, [selectedKecamatan, selectedDesa, selectedTps]);

  const refreshToken = async () => {
    try {
      const response = await axios.get('https://api.masboy.id/token');
      setToken(response.data.accessToken);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpired(decoded.exp);
    } catch (error) {
      if (error.response) {
        navigate('/');
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expired * 1000 < currentDate.getTime()) {
        const response = await axios.get('https://api.masboy.id/token');
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpired(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getVoters = async () => {
    try {
      const response = await axiosJWT.get('https://api.masboy.id/voters-by-user-id', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          kecamatan: selectedKecamatan? selectedKecamatan.value : '',
          desa: selectedDesa? selectedDesa.value : '',
          tps: selectedTps? selectedTps.value : '',
          id: userId
        },
      });
      setVoters(response.data);
      fetchRekruter(userId);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchKecamatanOptions = async () => {
    try {
      const response = await axiosJWT.get('https://api.masboy.id/kecamatan', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setKecamatanOptions(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDesaOptions = async (kecamatan) => {
    try {
      const response = await axiosJWT.get(`https://api.masboy.id/desa`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          kecamatan: kecamatan,
        },
      });
      setDesaOptions(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTpsOptions = async (desa) => {
    try {
      const response = await axiosJWT.get(`https://api.masboy.id/tps`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          desa: desa,
        },
      });
      setTpsOptions(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRekruter = async (userId) => {
    try {
      const response = await axiosJWT.get(`https://api.masboy.id/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSelectedRekruterName(response.data[0].nama);
    } catch (error) {
      console.log(error);
    }
  };

  const handleKecamatanChange = (selectedOption) => {
    setSelectedKecamatan(selectedOption);
    setSelectedDesa(''); // Reset desa when kecamatan changes
  };

  const handleDesaChange = (selectedOption) => {
    setSelectedDesa(selectedOption);
  };

  const handleTpsChange = (selectedOption) => {
    setSelectedTps(selectedOption);
  };

  const handleVoterSelect = (id) => {
    setSelectedVoterIds((prev) =>
      prev.includes(id) ? prev.filter((voterId) => voterId !== id) : [...prev, id]
    );
  };

  // New function to select/deselect all voters
  const handleSelectAll = () => {
    if (selectedVoterIds.length === currentVoters.length) {
      setSelectedVoterIds([]); // Deselect all if all are selected
    } else {
      const allVoterIds = currentVoters.map(voter => voter.id);
      setSelectedVoterIds(allVoterIds); // Select all
    }
  };

  const handleSubmit = async () => {
    if (selectedVoterIds.length === 0) {
      setMsg('Silakan pilih pemilih terlebih dahulu!');
      setAlertMode('danger');
      return;
    }

    try {
      const response = await axiosJWT.post('https://api.masboy.id/reassign-voter', {
        voterIds: selectedVoterIds,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMsg(response.data.msg);
      setAlertMode('success');
      setSelectedVoterIds([]); // Reset selected voter IDs after submission
      getVoters(); // Refresh the voter list
    } catch (error) {
      console.log(error);
      setMsg('Terjadi kesalahan saat membatalkan pemilih!');
      setAlertMode('danger');
    }
  };

  // Helper function to generate filter summary
  const getFilterSummary = () => {
    return `Kecamatan: ${selectedKecamatan? selectedKecamatan.value : 'Semua'}, Desa: ${selectedDesa? selectedDesa.value : 'Semua'}, TPS: ${selectedTps? selectedTps.value : 'Semua'}, Rekruter: ${selectedRekruterName || 'Semua'}`;
  };

  // Export to PDF
 // Export to PDF
 const exportToPDF = () => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  let startY = 10;
  
  // Fungsi untuk menambah header pada halaman pertama
  const addHeader = (imgData) => {
  
    // Set lebar gambar sesuai dengan lebar dokumen dan jaga rasio aspek gambar
    const imgWidth = pageWidth; // Gambar memenuhi lebar dokumen
    const imgHeight = (13 / 100) * pageHeight; // Sesuaikan tinggi gambar dengan menjaga rasio aspek
  
    // Menambahkan gambar di bagian atas header
    doc.addImage(imgData, 'PNG', 0, 10, imgWidth, imgHeight); // Menempatkan gambar di bagian atas, mulai dari x=0
  
    // Set font size and family
    doc.setFont("times", "bold");
    doc.setTextColor(0, 0, 0); // Warna teks hitam
  
    // Mengatur jarak startY di bawah gambar
    const startY = imgHeight + 20; // Tambahkan jarak lebih besar jika teks tidak muncul (dari 20 ke 30)
  
    doc.setFontSize(12);
    doc.setFont("times", "normal"); // Mengatur font ke Times New Roman
    doc.text('DAFTAR PEMILIH PASLON 2', pageWidth / 2, startY, { align: 'center' });
    doc.text('PEMILIHAN BUPATI DAN WAKIL BUPATI PEMALANG 2024', pageWidth / 2, startY + 7, { align: 'center' });

    doc.setFontSize(12);
    const rightX = pageWidth - 15; // Posisi x untuk teks yang diselaraskan ke kanan
    doc.text(`KECAMATAN: ${selectedKecamatan? selectedKecamatan.value : 'SEMUA'}`, 15, startY + 14);
    doc.text(`DESA: ${selectedDesa? selectedDesa.value : 'SEMUA'}`, 15, startY + 21);
    doc.text(`TPS: ${selectedTps? selectedTps.value : 'SEMUA'}`, 15, startY + 28);
    doc.text(`REKRUTER: ${selectedRekruterName.toUpperCase() || 'SEMUA'}`, rightX, startY + 14, { align: 'right' }); // Ditaruh di kanan
    doc.text(`JUMLAH DATA: ${voters.length}`, rightX, startY + 21, { align: 'right' }); // Ditaruh di kanan
};   

  // Fungsi untuk menambah footer halaman
  const addFooter = (page) => {
    doc.setFontSize(12);
    doc.setFont("times", "normal"); // Mengatur font ke Times New Roman
    doc.text(`Halaman ${page}`, 105, pageHeight - 10, { align: 'center' });
  };

  // Menambahkan header di halaman pertama
  addHeader(KopSurat);
  
  // Tabel data dimulai di bawah header
  let tableY = startY + 81;

  doc.autoTable({
    startY: tableY,
    head: [['NO', 'NAMA', 'UMUR', 'KELAMIN', 'KECAMATAN', 'DESA', 'TPS']],
    body: voters.map((voter, index) => [
      index + 1,
      voter.nama,
      voter.umur,
      voter.kelamin,
      voter.kecamatan,
      voter.desa,
      voter.tps,
    ]),
    margin: { top: 20 },
    didDrawPage: (data) => {
      let pageCount = doc.internal.getNumberOfPages();
      if (pageCount > 1) {
        doc.setPage(pageCount);
        addFooter(pageCount);
      }
    },
    addPageContent: (data) => {
      if (data.pageCount > 1) {
        doc.setFontSize(10);
        addFooter(data.pageCount);
      }
    },
    styles: {
      fontSize: 10,
      font: "times", // Mengatur font untuk isi tabel ke Times New Roman
      lineWidth: 0.5, // Ketebalan garis border
      textColor: [0, 0, 0], // Warna border hitam
      lineColor: [0, 0, 0], // Warna border hitam
    },
    headStyles: {
      fillColor: [30, 30, 30],
      halign: 'center',
      textColor: [255, 255, 255], // Warna teks di header
      lineWidth: 0.5, // Ketebalan garis border untuk header
      lineColor: [0, 0, 0], // Warna border hitam untuk header
    },
    columnStyles: {
      0: {cellWidth: 10 },
      1: { cellWidth: 45 },
      2: { cellWidth: 20 },
      3: {  cellWidth: 25 },
      4: {  cellWidth: 30 },
      5: {  cellWidth: 35 },
      6: {  cellWidth: 15 },
      7: {  cellWidth: 25 },
    },
  });

  // Menyimpan dokumen PDF
  doc.save(`DAFTAR_PEMILIH_OLEH_${selectedRekruterName.toUpperCase() || 'SEMUA'}.pdf`);
};


  // Export to Excel
  const exportToExcel = () => {
    const filterSummary = getFilterSummary();
    const worksheetData = [
      ['DATA PEMILIH MANSUR BOBBY'],
      ['FILTER:', filterSummary.toUpperCase()],
      ['JUMLAH DATA:', voters.length],
      [],
      ['NO', 'NAMA', 'UMUR', 'KELAMIN', 'KECAMATAN', 'DESA', 'TPS'],
      ...voters.map((voter, index) => [
        index + 1,
        voter.nama,
        voter.umur,
        voter.kelamin,
        voter.kecamatan,
        voter.desa,
        voter.tps,
      ]),
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'DAFTAR PEMILIH');
    XLSX.writeFile(workbook, `DAFTAR_PEMILIH_OLEH_${selectedRekruterName.toUpperCase() || 'SEMUA'}.xlsx`);
  };

  // Get current voters for pagination
  const indexOfLastVoter = currentPage * rowsPerPage;
  const indexOfFirstVoter = indexOfLastVoter - rowsPerPage;
  const currentVoters = voters
    .filter(voter => voter.nama.toLowerCase().includes(searchName.toLowerCase())) // Filter based on search name
    .slice(indexOfFirstVoter, indexOfLastVoter);

  // Calculate total pages
  const totalPages = Math.ceil(voters.length / rowsPerPage);
  const maxPageNumbersToShow = 5;

  // Calculate the range of page numbers to show
  const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
  const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page when changing rows per page
  };

  return (
    <Container className="mt-5 p-5">
      <a className='text-decoration-none d-flex align-items-center gap-3' style={{ color: '#008000' }} onClick={() => window.history.back()} href='#'>
        <IoMdArrowRoundBack />
        Kembali
      </a>
      <h2 className="mb-2 text-center text-uppercase h1"
        style={{ 
          color: '#008000',
          fontWeight: 'bolder'
         }}
      >Daftar Inputan Pemilih Paslon 2</h2>
      <p className="mb-5 h6 text-center"
        style={{ fontWeight:'normal' }}
      >Oleh <span style={{ fontWeight: 'bold', color: '#008000' }}>{selectedRekruterName}</span></p>
      <hr />
      {msg && <Alert variant={alertMode} className="text-center">{msg}</Alert>}
      <div className='sticky-top' style={{top: 68}}>
      <div className="bg-light border p-3">
        <Row className="align-items-center">
          <Col md={6}>
            <Form.Control
              type="text"
              placeholder="Cari berdasarkan nama..."
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
            />
          </Col>
          <Col md={2}>
          <Form.Select value={rowsPerPage} onChange={handleRowsPerPageChange}>
            <option value={100}>100</option>
            <option value={250}>250</option>
            <option value={500}>500</option>
            <option value={voters.length}>Semua</option>
          </Form.Select>
          </Col>
          <Col md={4} className="text-end d-flex align-items-center">
            <Button onClick={handleSubmit} variant="danger" className='me-2 d-flex gap-1 align-items-center'>
              <FaTrashAlt />
              Hapus Pemilih
            </Button>
            <span className="ms-3">{selectedVoterIds.length} Pemilih Terpilih</span>
          </Col>
        </Row>
      </div>
      <div className='p-3 border bg-light'>
      <Row>
        <Col md={sessionStorage.getItem('jabatan') === 'Admin' ? 3 : 4}>
        <Select
          value={selectedKecamatan}
          onChange={handleKecamatanChange}
          options={kecamatanOptions.map((kec) => ({ value: kec.kecamatan, label: kec.kecamatan }))}
          placeholder="Pilih Kecamatan"
          isClearable
          noOptionsMessage={() => 'Tidak ada data kecamatan'}
          />
        </Col>
        <Col md={sessionStorage.getItem('jabatan') === 'Admin' ? 3 : 4}>
        <Select
          value={selectedDesa}
          onChange={handleDesaChange}
          options={desaOptions.map((desa) => ({ value: desa.desa, label: desa.desa }))}
          placeholder="Pilih Desa"
          isClearable
          noOptionsMessage={() => 'Tidak ada data desa'}
          isDisabled={!selectedKecamatan}
          />
        </Col>
        <Col md={sessionStorage.getItem('jabatan') === 'Admin' ? 2 : 4}>
        <Select
          value={selectedTps}
          onChange={handleTpsChange}
          options={tpsOptions.map((tps) => ({ value: tps.tps, label: tps.tps }))}
          placeholder="Pilih TPS"
          isClearable
          noOptionsMessage={() => 'Tidak ada data TPS'}
          isDisabled={!selectedDesa}
          />
        </Col>
        {sessionStorage.getItem('jabatan') === 'Admin' && (
          <Col className="text-start d-flex gap-2" md={4}>
          <Button variant="success" onClick={() => {
              if (voters.length > 0) {
                exportToExcel();
              } else {
                setMsg('Tidak ada data untuk diekspor!');
              }
            }} className="d-flex gap-1 align-items-center"
            style={{ backgroundColor: '#008000'}}
          >
            <FaFileExcel />
            Unduh Excel
          </Button>
          <Button variant="danger" onClick={() => {
          if (voters.length > 0) {
            exportToPDF();
          } else {
            setMsg('Tidak ada data untuk diekspor!');
          }
        }} className="d-flex gap-1 align-items-center bg-transparent text-danger"
        >
            <FaFilePdf />
            Unduh PDF
          </Button>
          </Col>
        )}
      </Row>
      </div>
      </div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Nama</th>
            <th>Umur</th>
            <th>Kelamin</th>
            <th>Kecamatan</th>
            <th>Desa</th>
            <th>TPS</th>
            <th>
              <Form.Check
                  type="checkbox"
                  checked={selectedVoterIds.length === currentVoters.length}
                  onChange={handleSelectAll}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {currentVoters.map((voter, index) => (
            <tr key={voter.id} onClick={() => handleVoterSelect(voter.id)}>
              <td>{index + 1 + indexOfFirstVoter}</td>
              <td>{voter.nama}</td>
              <td>{voter.umur}</td>
              <td>{voter.kelamin}</td>
              <td>{voter.kecamatan}</td>
              <td>{voter.desa}</td>
              <td>{voter.tps}</td>
              <td onClick={(e) => e.stopPropagation()}>
                <Form.Check
                  type="checkbox"
                  checked={selectedVoterIds.includes(voter.id)}
                  onChange={() => handleVoterSelect(voter.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination className="justify-content-center">
            {startPage > 1 && (
              <Pagination.First onClick={() => paginate(1)} />
            )}
            {Array.from({ length: endPage - startPage + 1 }, (_, idx) => startPage + idx).map((number) => (
              <Pagination.Item
                key={number}
                active={number === currentPage}
                onClick={() => paginate(number)}
              >
                {number}
              </Pagination.Item>
            ))}
            {endPage < totalPages && (
              <Pagination.Last onClick={() => paginate(totalPages)} />
            )}
          </Pagination>
    </Container>
  );
};

export default VotersByRekruter;
