import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import Select from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Form, Button, Alert, Row, Col } from 'react-bootstrap';
import { IoMdArrowRoundBack } from "react-icons/io";

const EditUserPage = () => {
    const [name, setName] = useState("");
    const [nik, setNik] = useState('');
    const [nama, setNama] = useState('');
    const [noHp, setNoHp] = useState('');
    const [jabatan, setJabatan] = useState('');
    const [selectedKecamatan, setSelectedKecamatan] = useState('');
  const [kecamatanOptions, setKecamatanOptions] = useState([]);
  const [desaOptions, setDesaOptions] = useState([]);
  const [selectedDesa, setSelectedDesa] = useState('');
  const [selectedTps, setSelectedTps] = useState('');
  const [tpsOptions, setTpsOptions] = useState([]);
    const [role, setRole] = useState('');
    const [userId, setUserId] = useState("");
    const [token, setToken] = useState("");
    const [expired, setExpired] = useState("");
    const [users, setUsers] = useState("");
    const [msg, setMsg] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [alertMode, setAlertMode] = useState('');
    const params = useParams();
    const navigate = useNavigate();
  
    useEffect(() => {
      if (sessionStorage.getItem('jabatan') === 'Rekruter') {
        navigate('/daftar-dpt');
      }
      refreshToken();
      fetchUser();
      fetchKecamatanOptions();
    },[params.userId]);

    useEffect(() => {
      if (selectedDesa) {
        fetchTpsOptions(selectedDesa.value);
      } else {
        setTpsOptions([]);
        setSelectedTps('');
      }
    }, [selectedDesa]);
  
    useEffect(() => {
      if (selectedKecamatan) {
        fetchDesaOptions(selectedKecamatan.value);
      } else {
        setDesaOptions([]);
        setSelectedDesa('');
      }
    }, [selectedKecamatan, selectedDesa, selectedTps]);
  
    const fetchUser = async() => {
      try {
        const response = await axiosJWT.get(`https://api.masboy.id/users/${params.userId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const userData = response.data[0];
        setUserId(userData.id);
        setNik(userData.nik);
        setNama(userData.nama);
        setNoHp(userData.no_hp);
        setJabatan(userData.jabatan);
        setSelectedKecamatan({ value: userData.kecamatan, label: userData.kecamatan });
    setSelectedDesa({ value: userData.desa, label: userData.desa });
    setSelectedTps({ value: userData.tps, label: userData.tps });
        setRole(userData.role);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    }
  
    const updateUser = async(e) => {
      e.preventDefault();
      try {
        const response = await axiosJWT.post(`https://api.masboy.id/edit-user/${userId}`, {
            nik: nik,
            nama: nama,
            no_hp: noHp,
            jabatan: jabatan,
            kecamatan: selectedKecamatan? selectedKecamatan.value : '',
        desa: selectedDesa? selectedDesa.value : '',
        tps: selectedTps? selectedTps.value : '',
            role: role,
        }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        setMsg(response.data.msg);
        setAlertMode('success');
        // Redirect ke halaman lain setelah berhasil, jika perlu
        navigate('/rekruters');
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
          setAlertMode('danger');
        }
      }
    }
  
    const refreshToken = async () => {
        try {
          const response = await axios.get(`https://api.masboy.id/token`);
          setToken(response.data.accessToken);
          const decoded = jwtDecode(response.data.accessToken);
          setName(decoded.name);
          setExpired(decoded.exp);
        } catch (error) {
          if (error.response) {
            navigate('/');
          }
        }
      };
    
      const axiosJWT = axios.create();
    
      axiosJWT.interceptors.request.use(
        async (config) => {
          const currentDate = new Date();
          if (expired * 1000 < currentDate.getTime()) {
            const response = await axios.get('https://api.masboy.id/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setName(decoded.name);
            setExpired(decoded.exp);
          }
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );

      const fetchKecamatanOptions = async () => {
        try {
          const response = await axiosJWT.get('https://api.masboy.id/kecamatan', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setKecamatanOptions(response.data);
        } catch (error) {
          console.log(error);
        }
      };
    
      const fetchDesaOptions = async (kecamatan) => {
        try {
          const response = await axiosJWT.get(`https://api.masboy.id/desa`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              kecamatan: kecamatan,
            },
          });
          setDesaOptions(response.data);
        } catch (error) {
          console.log(error);
        }
      };
    
      const fetchTpsOptions = async (desa) => {
        try {
          const response = await axiosJWT.get(`https://api.masboy.id/tps`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              desa: desa,
            },
          });
          setTpsOptions(response.data);
        } catch (error) {
          console.log(error);
        }
      };

      const handleKecamatanChange = (selectedOption) => {
        setSelectedKecamatan(selectedOption);
        setSelectedDesa(''); // Reset desa when kecamatan changes
      };
    
      const handleDesaChange = (selectedOption) => {
        setSelectedDesa(selectedOption);
      };
    
      const handleTpsChange = (selectedOption) => {
        setSelectedTps(selectedOption);
      };

  return (
    <Container className="mt-5 p-5">
        <a className='text-decoration-none d-flex align-items-center gap-3' style={{ color: '#008000' }} onClick={() => window.history.back()} href='#'>
        <IoMdArrowRoundBack />
        Kembali
      </a>
      <h2 className="mb-5 text-center text-uppercase h1"
        style={{ 
          color: '#008000',
          fontWeight: 'bold'
         }}><b>Edit Data</b> {nama}</h2>
      <hr />
      {msg && <Alert variant={alertMode} className="text-center">{msg}</Alert>}
      <Form onSubmit={updateUser}>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="nik">
              <Form.Label>NIK</Form.Label>
              <Form.Control
                type="text"
                placeholder="Masukkan NIK"
                value={nik}
                onChange={(e) => setNik(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="nama">
              <Form.Label>Nama</Form.Label>
              <Form.Control
                type="text"
                placeholder="Masukkan Nama"
                value={nama}
                onChange={(e) => setNama(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group controlId="noHp">
              <Form.Label>No HP</Form.Label>
              <Form.Control
                type="text"
                placeholder="Masukkan No HP"
                value={noHp}
                onChange={(e) => setNoHp(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="jabatan">
              <Form.Label>Jabatan</Form.Label>
              <Form.Control
                type="text"
                placeholder="Masukkan Jabatan"
                value={jabatan}
                onChange={(e) => setJabatan(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group controlId="kecamatan">
              <Form.Label>Kecamatan</Form.Label>
              <Select
          value={selectedKecamatan}
          onChange={handleKecamatanChange}
          options={kecamatanOptions.map((kec) => ({ value: kec.kecamatan, label: kec.kecamatan }))}
          placeholder="Pilih Kecamatan"
          isClearable
          defaultValue={selectedKecamatan}
          noOptionsMessage={() => 'Tidak ada data kecamatan'}
          isDisabled={sessionStorage.getItem('jabatan') !== 'Admin'}
          />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="desa">
              <Form.Label>Desa</Form.Label>
              <Select
          value={selectedDesa}
          onChange={handleDesaChange}
          options={desaOptions.map((desa) => ({ value: desa.desa, label: desa.desa }))}
          placeholder="Pilih Desa"
          isClearable
          noOptionsMessage={() => 'Tidak ada data desa'}
          isDisabled={!selectedKecamatan}
          />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group controlId="tps">
              <Form.Label>TPS</Form.Label>
              <Select
          value={selectedTps}
          onChange={handleTpsChange}
          options={tpsOptions.map((tps) => ({ value: tps.tps, label: tps.tps }))}
          placeholder="Pilih TPS"
          isClearable
          noOptionsMessage={() => 'Tidak ada data TPS'}
          isDisabled={!selectedDesa}
          />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="role">
              <Form.Label>Role</Form.Label>
              <Form.Control as="select" value={role} onChange={(e) => setRole(e.target.value)} required>
                <option value="Rekruter">Rekruter</option>
                <option value="Operator">Operator</option>
                {sessionStorage.getItem('jabatan') === 'Admin' && (
                  <option value="Admin">Admin</option>
                )}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Button type="submit" style={{ 
                      backgroundColor: '#008000',
                      border: 'none'
                     }}>
          Edit Data User
        </Button>
      </Form>
    </Container>
  );
};

export default EditUserPage;
