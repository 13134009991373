import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Container, Dropdown, Accordion, Button } from 'react-bootstrap';
import { jwtDecode } from 'jwt-decode';
import { FaUserLarge } from 'react-icons/fa6';
import { RiDashboard2Line } from "react-icons/ri";
import { IoDocumentTextOutline } from "react-icons/io5";
import { MdOutlineHowToVote } from "react-icons/md";
import { BsPersonRaisedHand, BsPersonBadge, BsFillPersonPlusFill, BsFillPinMapFill } from "react-icons/bs";

const SidebarComponent = ({ children }) => {
  const [name, setName] = useState('');
  const [id, setId] = useState(0);
  const [token, setToken] = useState('');
  const [expired, setExpired] = useState('');
  const [voters, setVoters] = useState([]);
  const navigate = useNavigate();
  const [isAdminOpen, setIsAdminOpen] = useState(false);

  useEffect(() => {
    refreshToken();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get('https://api.masboy.id/token');
      setToken(response.data.accessToken);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpired(decoded.exp);
    } catch (error) {
      if (error.response) {
        navigate('/');
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expired * 1000 < currentDate.getTime()) {
        const response = await axios.get('https://api.masboy.id/token');
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpired(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const Logout = async () => {
    try {
      await axios.delete('https://api.masboy.id/logout');
      sessionStorage.clear();
      navigate('/');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container fluid className="d-flex p-0">
      {/* Sidebar */}
      <div className="sidebar shadow-lg" style={{ width: '260px', height: '100vh', position: 'fixed', backgroundColor: '#008000', zIndex: 1000 }}>
        <h1 className="h2 text-white my-4 p-3 text-uppercase" style={{ fontWeight: 'bold' }}>Data Center Masboy</h1>
        <nav className="nav flex-column gap-3">
          <div className='d-flex flex-column'>
          {sessionStorage.getItem('role') !== 'Rekruter' && (
            <>
              <a className="nav-link text-white d-flex gap-3 align-items-center" style={{ fontWeight: 'normal', fontSize: '1.2rem' }} href="/rekruters">
                <BsPersonBadge />
                Daftar Rekruter
              </a>
              <a className="nav-link text-white d-flex gap-3 align-items-center" style={{ fontWeight: 'normal', fontSize: '1.2rem' }} href="/create-user">
              <BsFillPersonPlusFill />
                Buat User
              </a>
              <a className="nav-link text-white d-flex gap-3 align-items-center" style={{ fontWeight: 'normal', fontSize: '1.2rem' }} href="/all-voters">
                <BsPersonRaisedHand />
                Daftar Pemilih
              </a>
              {sessionStorage.getItem('role') === 'Admin' && (
                <>
                <a className="nav-link text-white d-flex gap-3 align-items-center" style={{ fontWeight: 'normal', fontSize: '1.2rem' }} href="/geomap">
                <BsFillPinMapFill />
                Peta Sebaran
              </a>
                </>
              )}
            </>
            )}
            <a className="nav-link text-white d-flex gap-3 align-items-center" style={{ fontWeight: 'normal', fontSize: '1.2rem' }} href="/dashboard">
              <RiDashboard2Line />
              Dashboard
            </a>
            <a className="nav-link text-white d-flex gap-3 align-items-center" style={{ fontWeight: 'normal', fontSize: '1.2rem' }} href="/daftar-dpt">
              <IoDocumentTextOutline />
              DPT
            </a>
            <a className="nav-link text-white d-flex gap-3 align-items-center" style={{ fontWeight: 'normal', fontSize: '1.2rem' }} href="/user-voters">
              <MdOutlineHowToVote />
              Daftar Inputan
            </a>
          </div>
        </nav>
      </div>
      
      {/* Main content */}
      <div className="content" style={{ marginLeft: '260px', width: '100%' }}>
        <div>
          <div className='fixed-top w-100 text-white d-flex justify-content-end align-items-center px-5 py-3' style={{ backgroundColor: '#008000', zIndex: 900 }}>
            <Dropdown align="end" className="">
              <Dropdown.Toggle id="dropdown-basic"
                className="bg-transparent btn btn-light text-white d-flex align-items-center"
                style={{ fontWeight: 'bolder', border: 'none' }}
              >
                {name || 'User'}
                <FaUserLarge className='ms-3'/>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={Logout}>Log Out</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {children}
        </div>
      </div>
    </Container>
  );
};

export default SidebarComponent;
