import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Container, Row, Col, Alert, Form, Pagination } from 'react-bootstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import KopSurat from './assets/kop-surat.png';
import { FaFileExcel, FaFilePdf } from 'react-icons/fa';

const AllVoters = () => {
  const [name, setName] = useState('');
  const [token, setToken] = useState('');
  const [expired, setExpired] = useState('');
  const [voters, setVoters] = useState([]);
  const [kecamatanOptions, setKecamatanOptions] = useState([]);
  const [desaOptions, setDesaOptions] = useState([]);
  const [rekruterOptions, setRekruterOptions] = useState([]);
  const [selectedKecamatan, setSelectedKecamatan] = useState('');
  const [selectedDesa, setSelectedDesa] = useState('');
  const [selectedTps, setSelectedTps] = useState('');
  const [selectedRekruter, setSelectedRekruter] = useState('');
  const [tpsOptions, setTpsOptions] = useState([]); // State untuk opsi TPS
  const [selectedRekruterName, setSelectedRekruterName] = useState('');
  // const [validRekruterOptions, setValidRekruterOptions] = useState([]);
  const [msg, setMsg] = useState('');
  // State for search
  const [searchName, setSearchName] = useState('');
  
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1); // Halaman saat ini
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem('jabatan') === 'Rekruter') {
      navigate('/daftar-dpt');
    } else if (!sessionStorage.getItem('jabatan') === 'Operator') {
      setSelectedKecamatan({ value: sessionStorage.getItem('kecamatan'), label: sessionStorage.getItem('kecamatan') });
      fetchDesaOptions(sessionStorage.getItem('kecamatan'));
    }
    refreshToken();
    fetchKecamatanOptions();
    fetchRekruterOptions();
    getVoters();
  }, []);

  useEffect(() => {
    if (selectedDesa) {
      fetchTpsOptions(selectedDesa.value);
    } else {
      setTpsOptions([]);
      setSelectedTps('');
    }
  }, [selectedDesa]);

  useEffect(() => {
    if (selectedRekruter) {
      fetchRekruter(selectedRekruter.value);
    }
  }, [selectedRekruter]);

  useEffect(() => {
    if (selectedKecamatan || sessionStorage.getItem('jabatan') === 'Operator') {
      fetchDesaOptions(selectedKecamatan.value ? selectedKecamatan.value : (sessionStorage.getItem('jabatan') === 'Operator' ? sessionStorage.getItem('kecamatan') : ''));
    } else {
      setDesaOptions([]);
      setSelectedDesa('');
    }
    getVoters();
    fetchRekruterOptions();
  }, [selectedKecamatan, selectedDesa, selectedTps, selectedRekruter]);

  const refreshToken = async () => {
    try {
      const response = await axios.get('https://api.masboy.id/token');
      setToken(response.data.accessToken);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpired(decoded.exp);
    } catch (error) {
      if (error.response) {
        navigate('/');
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expired * 1000 < currentDate.getTime()) {
        const response = await axios.get('https://api.masboy.id/token');
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpired(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getVoters = async () => {
    try {
      const response = await axiosJWT.get('https://api.masboy.id/all-voters', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          kecamatan: selectedKecamatan? selectedKecamatan.value : (sessionStorage.getItem('jabatan') === 'Operator' ? sessionStorage.getItem('kecamatan') : ''),
          desa: selectedDesa? selectedDesa.value : '',
          tps: selectedTps? selectedTps.value : '',
          rekruter: selectedRekruter? selectedRekruter.value : '',
        },
      });
      setVoters(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchKecamatanOptions = async () => {
    try {
      const response = await axiosJWT.get('https://api.masboy.id/kecamatan', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setKecamatanOptions(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDesaOptions = async (kecamatan) => {
    try {
      const response = await axiosJWT.get(`https://api.masboy.id/desa`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          kecamatan: kecamatan ? kecamatan : (sessionStorage.getItem('jabatan') === 'Operator' ? sessionStorage.getItem('kecamatan') : ''),
        },
      });
      setDesaOptions(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTpsOptions = async (desa) => {
    try {
      const response = await axiosJWT.get(`https://api.masboy.id/tps`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          desa: desa,
        },
      });
      setTpsOptions(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRekruterOptions = async () => {
    try {
      const response = await axiosJWT.get('https://api.masboy.id/users', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          kecamatan: selectedKecamatan? selectedKecamatan.value : (sessionStorage.getItem('jabatan') === 'Operator' ? sessionStorage.getItem('kecamatan') : ''),
          desa: selectedDesa? selectedDesa.value : '',
          tps: selectedTps? selectedTps.value : '',
        },
      });
  
      if (!response.data || response.data.length === 0) {
        console.log('Data tidak ditemukan'); // Menangani kasus data kosong
        setRekruterOptions([]);
      } else {
        setRekruterOptions(response.data);
      }
    } catch (error) {
      console.error('Terjadi kesalahan saat mengambil data:', error);
    }
  };
  

  const fetchRekruter = async (userId) => {
    try {
      const response = await axiosJWT.get(`https://api.masboy.id/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSelectedRekruterName(response.data[0].nama);
    } catch (error) {
      console.log(error);
    }
  };

  const handleKecamatanChange = (selectedOption) => {
    setSelectedKecamatan(selectedOption);
    setSelectedDesa(''); // Reset desa when kecamatan changes
  };

  const handleDesaChange = (selectedOption) => {
    setSelectedDesa(selectedOption);
  };

  const handleTpsChange = (selectedOption) => {
    setSelectedTps(selectedOption);
  };

  const handleRekruterChange = (selectedOption) => {
    setSelectedRekruter(selectedOption);
  };
  

  // Helper function to generate filter summary
  const getFilterSummary = () => {
    return `Kecamatan: ${selectedKecamatan? selectedKecamatan.value : 'Semua'}, Desa: ${selectedDesa? selectedDesa.value : 'Semua'}, TPS: ${selectedTps? selectedTps.value : 'Semua'}, Rekruter: ${selectedRekruterName || 'Semua'}`;
  };

  const getFormattedDateTime = () => {
    const now = new Date();
    const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
    const months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
  
    const dayName = days[now.getDay()];
    const day = now.getDate();
    const monthName = months[now.getMonth()];
    const year = now.getFullYear();
  
    // Format waktu dengan menambahkan leading zero jika diperlukan
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
  
    return `${dayName}, ${day} ${monthName} ${year} ${hours}:${minutes}`;
  };

  // Export to PDF
 // Export to PDF
 const exportToPDF = () => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  let startY = 10;
  
  // Fungsi untuk menambah header pada halaman pertama
  const addHeader = (imgData) => {
  
    // Set lebar gambar sesuai dengan lebar dokumen dan jaga rasio aspek gambar
    const imgWidth = pageWidth; // Gambar memenuhi lebar dokumen
    const imgHeight = (13 / 100) * pageHeight; // Sesuaikan tinggi gambar dengan menjaga rasio aspek
  
    // Menambahkan gambar di bagian atas header
    doc.addImage(imgData, 'PNG', 0, 10, imgWidth, imgHeight); // Menempatkan gambar di bagian atas, mulai dari x=0
  
    // Set font size and family
    doc.setFont("times", "bold");
    doc.setTextColor(0, 0, 0); // Warna teks hitam
  
    // Mengatur jarak startY di bawah gambar
    const startY = imgHeight + 20; // Tambahkan jarak lebih besar jika teks tidak muncul (dari 20 ke 30)
  
    doc.setFontSize(12);
    doc.setFont("times", "bold"); // Mengatur font ke Times New Roman
    doc.text('DAFTAR PEMILIH PASLON 2', pageWidth / 2, startY, { align: 'center' });
    doc.text('PEMILIHAN BUPATI DAN WAKIL BUPATI PEMALANG 2024', pageWidth / 2, startY + 7, { align: 'center' });

    doc.setFontSize(12);
    doc.setFont("times", "normal");
    const rightX = pageWidth - 15; // Posisi x untuk teks yang diselaraskan ke kanan
    doc.text(`KECAMATAN: ${selectedKecamatan? selectedKecamatan.value : 'SEMUA'}`, 15, startY + 14);
    doc.text(`DESA: ${selectedDesa? selectedDesa.value : 'SEMUA'}`, 15, startY + 21);
    doc.text(`TPS: ${selectedTps? selectedTps.value : 'SEMUA'}`, 15, startY + 28);
    doc.text(`${getFormattedDateTime().toUpperCase()}`, rightX, startY + 14, { align: 'right' }); // Ditaruh di kanan
    doc.text(`REKRUTER: ${selectedRekruterName.toUpperCase() || 'SEMUA'}`, rightX, startY + 21, { align: 'right' }); // Ditaruh di kanan
    doc.text(`JUMLAH DATA: ${voters.length}`, rightX, startY + 28, { align: 'right' }); // Ditaruh di kanan
};   

  // Fungsi untuk menambah footer halaman
  const addFooter = (page) => {
    doc.setFontSize(12);
    doc.setFont("times", "normal"); // Mengatur font ke Times New Roman
    doc.text(`HALAMAN ${page}`, 105, pageHeight - 10, { align: 'center' });
  };

  // Menambahkan header di halaman pertama
  addHeader(KopSurat);
  
  // Tabel data dimulai di bawah header
  let tableY = startY + 81;

  doc.autoTable({
    startY: tableY,
    head: [['NO', 'NAMA', 'UMUR', 'L/P', 'KECAMATAN', 'DESA', 'TPS', 'REKRUTER']],
    body: voters.map((voter, index) => [
      index + 1,
      voter.nama.toUpperCase(),
      voter.umur,
      voter.kelamin.toUpperCase(),
      voter.kecamatan.toUpperCase(),
      voter.desa.toUpperCase(),
      voter.tps,
      voter.user.nama.toUpperCase(),
    ]),
    margin: { top: 20 },
    didDrawPage: (data) => {
      let pageCount = doc.internal.getNumberOfPages();
      if (pageCount > 1) {
        doc.setPage(pageCount);
        addFooter(pageCount);
      }
    },
    addPageContent: (data) => {
      if (data.pageCount > 1) {
        doc.setFontSize(10);
        addFooter(data.pageCount);
      }
    },
    styles: {
      fontSize: 10,
      font: "times", // Mengatur font untuk isi tabel ke Times New Roman
      lineWidth: 0.5, // Ketebalan garis border
      textColor: [0, 0, 0], // Warna border hitam
      lineColor: [0, 0, 0], // Warna border hitam
    },
    headStyles: {
      fillColor: [30, 30, 30],
      halign: 'center',
      textColor: [255, 255, 255], // Warna teks di header
      lineWidth: 0.5, // Ketebalan garis border untuk header
      lineColor: [0, 0, 0], // Warna border hitam untuk header
    },
    columnStyles: {
      0: {cellWidth: 10 },
      1: { cellWidth: 35 },
      2: { cellWidth: 20 },
      3: {  cellWidth: 10 },
      4: {  cellWidth: 30 },
      5: {  cellWidth: 35 },
      6: {  cellWidth: 15 },
      7: {  cellWidth: 25 },
    },
  });

  // Menyimpan dokumen PDF
  doc.save(`DAFTAR_PEMILIH_${selectedKecamatan? selectedKecamatan.value : 'SEMUA'}_${selectedDesa? selectedDesa.value : 'SEMUA'}_${selectedTps? selectedTps.value : 'SEMUA'}_${selectedRekruterName || 'SEMUA'}.pdf`);
};

const exportToPDFRekruter = () => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  let pageCount = 0;

  // Fungsi untuk menambah header pada setiap halaman
  const addHeader = (imgData, rekruter) => {
    const imgWidth = pageWidth;
    const imgHeight = (13 / 100) * pageHeight;

    doc.addImage(imgData, 'PNG', 0, 10, imgWidth, imgHeight);

    const startY = imgHeight + 20;

    doc.setFont("times", "bold");
    doc.setFontSize(12);
    doc.text('DAFTAR PEMILIH PASLON 2', pageWidth / 2, startY, { align: 'center' });
    doc.text('PEMILIHAN BUPATI DAN WAKIL BUPATI PEMALANG 2024', pageWidth / 2, startY + 7, { align: 'center' });

    doc.setFontSize(12);
    doc.setFont("times", "normal");
    const rightX = pageWidth - 15;
    doc.text(`KECAMATAN: ${selectedKecamatan? selectedKecamatan.value : (sessionStorage.getItem('jabatan') === 'Operator' ? sessionStorage.getItem('kecamatan') : 'SEMUA')}`, 15, startY + 14);
    doc.text(`DESA: ${selectedDesa? selectedDesa.value : 'SEMUA'}`, 15, startY + 21);
    doc.text(`TPS: ${selectedTps? selectedTps.value : 'SEMUA'}`, 15, startY + 28);
    doc.text(`${getFormattedDateTime().toUpperCase()}`, rightX, startY + 14, { align: 'right' }); // Ditaruh di kanan
    doc.text(`REKRUTER: ${rekruter.nama.toUpperCase() || 'SEMUA'}`, rightX, startY + 21, { align: 'right' }); // Ditaruh di kanan
    doc.text(`JUMLAH DATA: ${voters.filter((voter) => voter.user.id === rekruter.id).length}`, rightX, startY + 28, { align: 'right' }); // Ditaruh di kanan

    return startY + 35; // Menyesuaikan posisi Y untuk tabel di bawah header
  };

  // Fungsi untuk menambah footer pada setiap halaman
  const addFooter = () => {
    doc.setFontSize(12);
    doc.setFont("times", "normal");
    doc.text(`HALAMAN ${pageCount}`, 105, pageHeight - 10, { align: 'center' });
  };

  rekruterOptions.forEach((rekruter, index) => {
    if (index > 0) doc.addPage(); // Tambahkan halaman baru jika bukan halaman pertama
    pageCount += 1;

    // Menambahkan header untuk setiap halaman
    const startY = addHeader(KopSurat, rekruter);

    const rekruterVoters = voters.filter((voter) => voter.user.id === rekruter.id);

    // Tabel data dimulai di bawah header
    doc.autoTable({
      startY,
      head: [['NO', 'NAMA', 'UMUR', 'L/P', 'KECAMATAN', 'DESA', 'TPS']],
      body: rekruterVoters.map((voter, i) => [
        i + 1,
        voter.nama.toUpperCase(),
        voter.umur,
        voter.kelamin.toUpperCase(),
        voter.kecamatan.toUpperCase(),
        voter.desa.toUpperCase(),
        voter.tps,
      ]),
      margin: { top: 20 },
      styles: {
        fontSize: 10,
        font: "times",
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
      },
      headStyles: {
        fillColor: [30, 30, 30],
        textColor: [255, 255, 255],
      },
      columnStyles: {
        0: { cellWidth: 10 },
        1: { cellWidth: 55 },
        2: { cellWidth: 20 },
        3: { cellWidth: 10 },
        4: { cellWidth: 30 },
        5: { cellWidth: 35 },
        6: { cellWidth: 15 },
      },
      didDrawPage: () => addFooter(),
    });
  });

  // Menyimpan dokumen PDF
  doc.save('DAFTAR_PEMILIH_REKRUTER.pdf');
};

  // Export to Excel
  const exportToExcel = () => {
    const filterSummary = getFilterSummary();
    const worksheetData = [
      ['DATA PEMILIH MANSUR BOBBY'],
      ['FILTER:', filterSummary.toUpperCase()],
      ['JUMLAH DATA:', voters.length],
      [],
      ['NO', 'NAMA', 'UMUR', 'L/P', 'KECAMATAN', 'DESA', 'TPS', 'REKRUTER'],
      ...voters.map((voter, index) => [
        index + 1,
      voter.nama.toUpperCase(),
      voter.umur,
      voter.kelamin.toUpperCase(),
      voter.kecamatan.toUpperCase(),
      voter.desa.toUpperCase(),
      voter.tps,
      voter.user.nama.toUpperCase(),
      ]),
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'DAFTAR PEMILIH');
    XLSX.writeFile(workbook, `DAFTAR_PEMILIH_${selectedKecamatan? selectedKecamatan.value : 'SEMUA'}_${selectedDesa? selectedDesa.value : 'SEMUA'}_${selectedTps? selectedTps.value : 'SEMUA'}_${selectedRekruterName || 'SEMUA'}.xlsx`);
  };

  // Get current voters for pagination
  const indexOfLastVoter = currentPage * rowsPerPage;
  const indexOfFirstVoter = indexOfLastVoter - rowsPerPage;
  const currentVoters = voters
    .filter(voter => voter.nama.toLowerCase().includes(searchName.toLowerCase())) // Filter based on search name
    .slice(indexOfFirstVoter, indexOfLastVoter);

  // Calculate total pages
  const totalPages = Math.ceil(voters.length / rowsPerPage);
  const maxPageNumbersToShow = 5;

  // Calculate the range of page numbers to show
  const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
  const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page when changing rows per page
  };

  return (
    <section>
      <Container className="mt-5 p-5">
      <h2 className="text-center text-uppercase h1 mb-5"
        style={{ 
          color: '#008000',
          fontWeight: 'bolder'
         }}
        >Daftar Pemilih Paslon 2</h2>
      {msg && <Alert variant="danger" role='button' className="text-center" onClick={() => setMsg('')}>{msg}</Alert>}
      <div className="sticky-top bg-light border p-3" style={{top: 68}}>
        <Row className="align-items-center">
        <Col md={6} className="text-start d-flex gap-2">
          <Button variant="success" onClick={() => {
              if (voters.length > 0) {
                exportToExcel();
              } else {
                setMsg('Tidak ada data untuk diekspor!');
              }
            }} className="me-2 d-flex gap-1 align-items-center"
            style={{ backgroundColor: '#008000' }}
          >
            <FaFileExcel />
            Unduh Excel
          </Button>
          <Button variant="danger" onClick={() => {
          if (voters.length > 0) {
            exportToPDF();
          } else {
            setMsg('Tidak ada data untuk diekspor!');
          }
        }} className="me-2 d-flex gap-1 align-items-center"
        >
            <FaFilePdf />
            Unduh PDF
          </Button>
          <Button variant="warning" onClick={() => {
          if (voters.length > 0) {
            exportToPDFRekruter();
          } else {
            setMsg('Tidak ada data untuk diekspor!');
          }
        }} className="me-2 d-flex gap-1 align-items-center"
        >
            <FaFilePdf />
            Unduh PDF by Rekruter
          </Button>
          </Col>
          <Col md={2}>
          <Form.Select value={rowsPerPage} onChange={handleRowsPerPageChange}>
            <option value={100}>100</option>
            <option value={250}>250</option>
            <option value={500}>500</option>
            <option value={voters.length}>Semua</option>
          </Form.Select>
          </Col>
          <Col md={4}>
            <Form.Control
              type="text"
              placeholder="Cari berdasarkan nama..."
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
            />
          </Col>
        </Row>
      </div>
      <div className="bg-light border p-3">
      <Row>
        <Col>
        <Select
          value={selectedKecamatan ? selectedKecamatan : sessionStorage.getItem('jabatan') === 'Operator' ? { value: sessionStorage.getItem('kecamatan'), label: sessionStorage.getItem('kecamatan') } : ''}
          onChange={handleKecamatanChange}
          options={kecamatanOptions.map((kec) => ({ value: kec.kecamatan, label: kec.kecamatan }))}
          placeholder="Pilih Kecamatan"
          isClearable
          noOptionsMessage={() => 'Tidak ada data kecamatan'}
          isDisabled={sessionStorage.getItem('jabatan') === 'Operator'}
          />
        </Col>
        <Col>
        <Select
          value={selectedDesa}
          onChange={handleDesaChange}
          options={desaOptions.map((desa) => ({ value: desa.desa, label: desa.desa }))}
          placeholder="Pilih Desa"
          isClearable
          noOptionsMessage={() => 'Tidak ada data desa'}
          isDisabled={!selectedKecamatan && !sessionStorage.getItem('kecamatan')}
          />
        </Col>
        <Col>
        <Select
          value={selectedTps}
          onChange={handleTpsChange}
          options={tpsOptions.map((tps) => ({ value: tps.tps, label: tps.tps }))}
          placeholder="Pilih TPS"
          isClearable
          noOptionsMessage={() => 'Tidak ada data TPS'}
          isDisabled={!selectedDesa}
          />
        </Col>
        <Col>
        <Select
          value={selectedRekruter}
          onChange={handleRekruterChange}
          options={rekruterOptions.map((rek) => ({ value: rek.id, label: rek.nama }))}
          placeholder="Pilih Rekruter"
          isClearable
          noOptionsMessage={() => 'Tidak ada data Rekruter'}
          />
          {/* <Form.Select value={selectedRekruter} onChange={handleRekruterChange}>
            <option value="">Pilih Rekruter</option>
            {rekruterOptions.length > 0 ? (
              rekruterOptions.map((rek) => (
                <option key={rek.id} value={rek.id}>{rek.nama}</option>
              ))
            ) : (
              <option disabled>Tidak ada data</option>
            )}
          </Form.Select> */}
        </Col>
      </Row>
      </div>
      {voters.length > 0 ? (
        <>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>No</th>
              <th>Nama</th>
              <th>Umur</th>
              <th>Kelamin</th>
              <th>Kecamatan</th>
              <th>Desa</th>
              <th>TPS</th>
              <th>Rekruter</th>
            </tr>
          </thead>
          <tbody>
            {console.log(currentVoters)}
            {currentVoters.map((voter, index) => (
              <tr key={voter.id}>
                <td>{index+1+(currentPage*10-10)}</td>
                <td>{voter.nama}</td>
                <td>{voter.umur}</td>
                <td>{voter.kelamin}</td>
                <td>{voter.kecamatan}</td>
                <td>{voter.desa}</td>
                <td>{voter.tps}</td>
                <td>{voter.user.nama}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination className="justify-content-center">
            {startPage > 1 && (
              <Pagination.First onClick={() => paginate(1)} />
            )}
            {Array.from({ length: endPage - startPage + 1 }, (_, idx) => startPage + idx).map((number) => (
              <Pagination.Item
                key={number}
                active={number === currentPage}
                onClick={() => paginate(number)}
              >
                {number}
              </Pagination.Item>
            ))}
            {endPage < totalPages && (
              <Pagination.Last onClick={() => paginate(totalPages)} />
            )}
          </Pagination>
        </>
      ) : (
        <h3 className="mb-2 text-danger text-center text-uppercase h6 mt-3"
          style={{ 
            color: '#008000'
          }}
        >Belum ada Pemilih!</h3>
      )}
    </Container>
    </section>
  );
};

export default AllVoters;
