import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Container, Row, Col, Alert, Form, Pagination } from 'react-bootstrap';
import { FaTrashAlt } from "react-icons/fa";

const VotersByUser = () => {
  const [name, setName] = useState('');
  const [token, setToken] = useState('');
  const [expired, setExpired] = useState('');
  const [voters, setVoters] = useState([]);
  const [kecamatanOptions, setKecamatanOptions] = useState([]);
  const [desaOptions, setDesaOptions] = useState([]);
  const [selectedKecamatan, setSelectedKecamatan] = useState('');
  const [selectedDesa, setSelectedDesa] = useState('');
  const [selectedTps, setSelectedTps] = useState('');
  const [tpsOptions, setTpsOptions] = useState([]); // State untuk opsi TPS
  const [selectedVoterIds, setSelectedVoterIds] = useState([]);
  const [msg, setMsg] = useState('');
  const [alertMode, setAlertMode] = useState('');
  // State for search
  const [searchName, setSearchName] = useState('');
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1); // Halaman saat ini
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const navigate = useNavigate();

  useEffect(() => {
    refreshToken();
    fetchKecamatanOptions();
    getVoters();
  }, []);

  useEffect(() => {
    if (selectedDesa) {
      fetchTpsOptions(selectedDesa.value);
    } else {
      setTpsOptions([]);
      setSelectedTps('');
    }
  }, [selectedDesa]);

  useEffect(() => {
    if (selectedKecamatan) {
      fetchDesaOptions(selectedKecamatan.value);
    } else {
      setDesaOptions([]);
      setSelectedDesa('');
    }
    getVoters();
  }, [selectedKecamatan, selectedDesa, selectedTps]);

  useEffect(() => {
    getVoters();
  }, [rowsPerPage, currentPage]);

  const refreshToken = async () => {
    try {
      const response = await axios.get('https://api.masboy.id/token');
      setToken(response.data.accessToken);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpired(decoded.exp);
    } catch (error) {
      if (error.response) {
        navigate('/');
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expired * 1000 < currentDate.getTime()) {
        const response = await axios.get('https://api.masboy.id/token');
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpired(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getVoters = async () => {
    try {
      const response = await axiosJWT.get('https://api.masboy.id/voters-by-user', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          kecamatan: selectedKecamatan? selectedKecamatan.value : '',
          desa: selectedDesa? selectedDesa.value : '',
          tps: selectedTps? selectedTps.value : '',
          page: currentPage, // Menyertakan parameter untuk halaman saat meminta data
          limit: rowsPerPage, // Menyertakan parameter untuk batas data saat meminta data
        },
      });
      setVoters(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchKecamatanOptions = async () => {
    try {
      const response = await axiosJWT.get('https://api.masboy.id/kecamatan', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setKecamatanOptions(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDesaOptions = async (kecamatan) => {
    try {
      const response = await axiosJWT.get(`https://api.masboy.id/desa`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          kecamatan: kecamatan,
        },
      });
      setDesaOptions(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTpsOptions = async (desa) => {
    try {
      const response = await axiosJWT.get(`https://api.masboy.id/tps`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          desa: desa,
        },
      });
      setTpsOptions(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleKecamatanChange = (selectedOption) => {
    setSelectedKecamatan(selectedOption);
    setSelectedDesa(''); // Reset desa when kecamatan changes
    setCurrentPage(1);
  };

  const handleDesaChange = (selectedOption) => {
    setSelectedDesa(selectedOption);
    setCurrentPage(1);
  };

  const handleTpsChange = (selectedOption) => {
    setSelectedTps(selectedOption);
    setCurrentPage(1);
  };

  const handleVoterSelect = (id) => {
    setSelectedVoterIds((prev) =>
      prev.includes(id) ? prev.filter((voterId) => voterId !== id) : [...prev, id]
    );
  };

  // New function to select/deselect all voters
  const handleSelectAll = () => {
    if (selectedVoterIds.length === currentVoters.length) {
      setSelectedVoterIds([]); // Deselect all if all are selected
    } else {
      const allVoterIds = currentVoters.map(voter => voter.id);
      setSelectedVoterIds(allVoterIds); // Select all
    }
  };

  const handleSubmit = async () => {
    if (selectedVoterIds.length === 0) {
      setMsg('Silakan pilih pemilih terlebih dahulu!');
      setAlertMode('danger');
      return;
    }

    try {
      const response = await axiosJWT.post('https://api.masboy.id/reassign-voter', {
        voterIds: selectedVoterIds,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMsg(response.data.msg);
      setAlertMode('success');
      setSelectedVoterIds([]); // Reset selected voter IDs after submission
      getVoters(); // Refresh the voter list
    } catch (error) {
      console.log(error);
      setMsg('Terjadi kesalahan saat membatalkan pemilih!');
      setAlertMode('danger');
    }
  };

  const currentVoters = voters.data?.filter(voter => voter.nama.toLowerCase().includes(searchName.toLowerCase()));

  // Calculate total pages
  const totalVoters = voters.totalVoters;
  const totalPages = Math.ceil(totalVoters / rowsPerPage);
  const maxPageNumbersToShow = 5;

  // Calculate the range of page numbers to show
  const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
  const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page when changing rows per page
  };

  return (
    <Container className="mt-5 p-5">
      <h2 className="mb-2 text-center text-uppercase h1"
        style={{ 
          color: '#008000',
          fontWeight: 'bolder'
         }}
      >Daftar Inputan Pemilih Paslon 2</h2>
      <p className="mb-5 h6 text-center"
        style={{ fontWeight:'normal' }}
      >Oleh <span style={{ fontWeight: 'bold', color: '#008000' }}>{name}</span></p>
      <hr />
      {msg && <Alert variant={alertMode} className="text-center">{msg}</Alert>}
      <div className="sticky-top bg-light border p-3" style={{top: 68}}>
        <Row className="align-items-center">
          <Col md={6}>
            <Form.Control
              type="text"
              placeholder="Cari berdasarkan nama..."
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
            />
          </Col>
          <Col md={2}>
          <Form.Select value={rowsPerPage} onChange={handleRowsPerPageChange}>
            <option value={100}>100</option>
            <option value={250}>250</option>
            <option value={500}>500</option>
            <option value={totalVoters}>Semua</option>
          </Form.Select>
          </Col>
          <Col md={4} className="text-end d-flex align-items-center">
            <Button onClick={handleSubmit} variant='danger' className='d-flex gap-1 align-items-center'>
              <FaTrashAlt />
              Hapus Pemilih
            </Button>
            <span className="ms-3">{selectedVoterIds.length} Pemilih Terpilih</span>
          </Col>
        </Row>
      </div>
      <div className='p-3 border bg-light'>
      <Row>
        <Col>
        <Select
          value={selectedKecamatan}
          onChange={handleKecamatanChange}
          options={kecamatanOptions.map((kec) => ({ value: kec.kecamatan, label: kec.kecamatan }))}
          placeholder="Pilih Kecamatan"
          isClearable
          noOptionsMessage={() => 'Tidak ada data kecamatan'}
          />
        </Col>
        <Col>
        <Select
          value={selectedDesa}
          onChange={handleDesaChange}
          options={desaOptions.map((desa) => ({ value: desa.desa, label: desa.desa }))}
          placeholder="Pilih Desa"
          isClearable
          noOptionsMessage={() => 'Tidak ada data desa'}
          isDisabled={!selectedKecamatan}
          />
        </Col>
        <Col>
        <Select
          value={selectedTps}
          onChange={handleTpsChange}
          options={tpsOptions.map((tps) => ({ value: tps.tps, label: tps.tps }))}
          placeholder="Pilih TPS"
          isClearable
          noOptionsMessage={() => 'Tidak ada data TPS'}
          isDisabled={!selectedDesa}
          />
        </Col>
      </Row>
      </div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Nama</th>
            <th>Umur</th>
            <th>Kelamin</th>
            <th>Kecamatan</th>
            <th>Desa</th>
            <th>TPS</th>
            <th>
              <Form.Check
                  type="checkbox"
                  checked={selectedVoterIds.length === currentVoters?.length}
                  onChange={handleSelectAll}
                />
                </th>
          </tr>
        </thead>
        <tbody>
          {currentVoters?.map((voter, index) => (
            <tr key={voter.id} onClick={() => handleVoterSelect(voter.id)}>
              <td>{index+1+(currentPage*rowsPerPage-rowsPerPage)}</td>
              <td>{voter.nama}</td>
              <td>{voter.umur}</td>
              <td>{voter.kelamin}</td>
              <td>{voter.kecamatan}</td>
              <td>{voter.desa}</td>
              <td>{voter.tps}</td>
              <td onClick={(e) => e.stopPropagation()}>
                <Form.Check
                  type="checkbox"
                  checked={selectedVoterIds.includes(voter.id)}
                  onChange={() => handleVoterSelect(voter.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination className="justify-content-center custom-pagination">
        {startPage > 1 && (
          <Pagination.First onClick={() => paginate(1)} />
        )}
        {Array.from({ length: endPage - startPage + 1 }, (_, idx) => startPage + idx).map((number) => (
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => paginate(number)}
          >
            {number}
          </Pagination.Item>
        ))}
        {endPage < totalPages && (
          <Pagination.Last onClick={() => paginate(totalPages)} />
        )}
      </Pagination>

    </Container>
  );
};

export default VotersByUser;
